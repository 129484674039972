.page--division{
	@mixin contentPadding {
		padding-top: 15px;
		padding-bottom: 15px;
	}
	.mainbanner{
		.container__inner{
			display: flex;
			justify-content: center;
			align-items: center;
			color: #fff;
			height: 100%;
		}
		&.hidden-xs{
			.container__inner{
				img{max-width: 150px;}
				h1{font-size: 64px;}
			}
		}
		&.hidden-lg{
			.container__inner{
				img{max-width: 90px;}
				h1{font-size: 26px;}
			}
		}
	}
	.introduction{
		.content{
			@include contentPadding;
			padding-top: 30px;
		}

		.title{margin-bottom: 20px;}
		.description{
			p{
				line-height:22px;
				margin-bottom: 10px;
				text-align: justify;
			}
		}
		.col{
			&.m6{
				padding-right: gutter(of 12);
				&:last-child{
					padding-right: 0;
					padding-left: gutter(of 12);
				}
			}
		}
	}
	.roster{
		.content{
			@include contentPadding;
		}

		.title{font-size: 64px;}
		.subtitle{font-size: 36px;}
		.flag-icon{margin-right: 6px;}
		.ba-list{
			.ba-list-item{
				margin-bottom: 28px;
				.title{
					margin-bottom: 0;
				}
				.subtitle{
					font-size: 24px;
					font-weight: 500;
					font-style: italic;
					line-height: 29px;
					opacity: .6;
				}
				.img{
					justify-content: center;
				}
			}
		}
		.ba-list-mobile{
			.ba-list-item-mobile{
				.img{
					.line-separator{
						bottom: 0;
						z-index: 3;
					}
				}
				.copy{
					.subtitle{
						font-size: 14px;
						line-height: 17px;
						letter-spacing: 0;
						font-style: italic;
						opacity: .8;
					}
					.socmed{
						margin-top: 35px;
						.socmed__ul{
							display: flex;
							margin-top: 5px;
						}
						.socmed__li{
							flex-shrink: 0;
							&:last-child{
								margin-right: 0;
								.socmed__a{
									padding-right: 0;
								}
							}
							.socmed__a{
								padding-right: 12px;
								transition: .3s ease;
								i{
									width: 36px;
									height: 36px;
									background-size: 36px;
								}
								&:hover{
									opacity: .8;
								}
							}
						}
					}
				}
			}
		}
		// .roster__list{
		// 	margin-top: 30px;
		// 	.roster__li{
		// 		height: 400px;
		// 		background-image: url(/images/bg-roster.jpg);
		// 		width:100%;
		// 		background-size: cover;
		// 		background-repeat: no-repeat;
		// 		background-position: center right;
		// 		position: relative;
		// 		overflow: hidden;
		// 		margin-bottom: 20px;
		// 		&:last-child{
		// 			margin-bottom: 0;
		// 		}
		// 		.roster__li__left{
		// 			height: 100%;
		// 			.inner{
		// 				padding-right: 110px;
		// 			}
		// 		}
		// 		.roster__li__right{
		// 			height: 100%;
		// 			.inner{
		// 				padding-left: 150px;
		// 				padding-right: 30px;
		// 			}
		// 		}
		// 		.inner{
		// 			position: relative;
		// 			height: 100%;
		// 			display: flex;
		// 			flex-direction: column;
		// 			align-items: center;
		// 			justify-content: center;
		// 		}
		// 		.bg--color{
		// 			height: 100%;
		// 			width:calc(50% + 72px);
		// 			background-image: linear-gradient(90deg, #F79A1C 0%, #FFC458 100%);
		// 			position: absolute;
		// 			transform: skewX(-20deg);
		// 			margin-left: -72px;
		// 		}
		// 		.bg--img{}
		// 		.roster--img{
		// 			position: absolute;
		// 			bottom: 0;
		// 			right: 0;
		// 			left: 0;
		// 			margin: auto;
		// 		}
		// 		.roster--profile{
		// 			hr{
		// 				border-bottom: 1px solid #F89D21;
		// 				margin: 8px 0 15px;
		// 			}
		// 			h6{
		// 				font-size: 20px;
		// 				display: block;
		// 				margin-bottom: 15px;
		// 				line-height: 22px;
		// 				color: rgba(56,56,56, .75);
		// 				&:last-child{
		// 					margin-bottom: 0;
		// 				}
		// 			}
		// 		}
		// 	}
		// }
		// .roster__list--m{
		// 	margin-top: 15px;
		// 	.roster__li{
		// 		background-image: linear-gradient(-179deg, #F79A1C 0%, #FFC458 100%);
		// 		border-radius: 2px;
		// 		border: 1px solid #e0e0e0;
		// 		position: relative;
		// 		overflow: hidden;
		// 		margin-bottom: 10px;

		// 		$height: 290px;
		// 		.roster__li__top{
		// 			height: $height;
		// 			border-top-left-radius: inherit;
		// 			border-top-right-radius: inherit;
		// 		}
		// 		.roster__li__bottom{
		// 			height: $height;
		// 			border-bottom-left-radius: inherit;
		// 			border-bottom-right-radius: inherit;
		// 			transform: skewY(-10deg);
		// 			margin-bottom: -9%;
		// 			z-index: 1;
		// 			position: relative;
		// 			overflow: hidden;
		// 			padding-top: 5%;
		// 			.bg--img{
		// 				background-image: url(/images/bg-roster.jpg);
		// 				width:100%;
		// 				height: 120%;
		// 				background-size: cover;
		// 				background-repeat: no-repeat;
		// 				background-position: center right;
		// 				transform: skewY(10deg);
		// 				margin-top: -68px;
		// 				position: absolute;
		// 				top:0;left:0;
		// 			}
		// 			.inner{
		// 				transform: skewY(10deg);
		// 			}
		// 			.roster--profile{
		// 				margin-top: 20px;
		// 				h6{
		// 					color: rgba(56,56,56, .75);
		// 				}
		// 			}
		// 		}
		// 		.roster--img{
		// 			position: absolute;
		// 			top: 5%;
		// 			z-index: 0;
		// 			left: 0;
		// 			right: 0;
		// 			margin: auto;
		// 			height: 300px;
		// 		}
		// 	}
		// }
	}
	.achievement{
		.content{
			@include contentPadding;
			padding-bottom: 50px;
		}
	}
	.teams-info{
		margin-bottom: 70px;
		.card{
			border: 0;
		}
	}
	.roster-tab-button{
		margin: 45px 0;
		overflow-x: hidden;
		overflow-y: visible;
		padding: 5px 0;
		position: relative;
		.roster-tab-button-wrapper{
			overflow-x: hidden;
			overflow-y: hidden;
			-webkit-overflow-scrolling: touch;
			white-space: nowrap;
			position: relative;
		}
		.roster-tab-button-list{
			transition: transform .2s ease-in-out, -webkit-transform .2s ease-in-out;
			position: relative;
			float: left;
		}
		ul{
			margin: 0;
			li{
				flex-shrink: 0;
				min-width: 190px;
				height: 65px;
				// background-color: #f3f3f3;
				color: rgba(#464646, .7);
				font-weight: 600;
				letter-spacing: 0.5px;
				font-size: 18px;
				text-align: right;
				margin-right: 10px;
				margin-left: 5px;
				transform: skewX(-4deg);
				display: inline-flex;
				justify-content: flex-end;
				align-items: center;
				cursor: pointer;
				position: relative;
				transition: .3s ease;
				&:before{
					content: '';
					width: 14px;
					height: 14px;
					border-top: solid 7px #fff;
					border-left: solid 7px #fff;
					border-right: solid 7px transparent;
					border-bottom: solid 7px transparent;
					position: absolute;
					top: 0px;
					left: -1px;
					z-index: 2;
					transform: skewX(0deg);
				}
				&:after{
					content: '';
					width: 14px;
					height: 14px;
					border-bottom: solid 7px #fff;
					border-right: solid 7px #fff;
					border-left: solid 7px transparent;
					border-top: solid 7px transparent;
					position: absolute;
					bottom: 0px;
					right: -1px;
					z-index: 2;
					transform: skewX(-4deg);
				}
				&:hover{
					.row{
						.text-box{
							background-image: linear-gradient(270deg, #f5f5f5 55%, rgba(235,235,235, .85) 75%, rgba(235,235,235, .55) 90%, rgba(235,235,235,.4) 100%);
						}
					}
				}
				&.active{
					color: #fff;
					pointer-events: none;
					.row{
						.text-box{
							background-image: linear-gradient(270deg, #ea8e12 48%, rgba(236, 154, 42, 0.85) 70%, rgba(236, 154, 42, 0.50) 90%, rgba(234, 142, 18, 0.35) 100%);
						}
					}
					h6{
						color: #fff;
					}
				}
				.row{
					width: 100%;
					height: 100%;
					.flag-box{
						position: absolute;
						z-index: 0;
						height: 100%;
					}
					.text-box{
						position: relative;
						height: 100%;
						z-index: 1;

						background-image: linear-gradient(270deg, #f8f8f8 55%, rgba(243,243,243, .85) 75%, rgba(243,243,243, .55) 90%, rgba(243,243,243,.4) 100%);
						padding: 10px;
						transition: .3s ease;
					}
				}
				.flag-icon{
					width: 100%;
					height: 100%;
					position: relative;
					background-size: cover;
					left: -1px;
					margin: 0;
					&:before{
						display: none;
					}
				}
				h6{
					font-weight: 600;
					letter-spacing: .5px;
					color: rgba(#464646, .7);
				}
			}
		}
		.tab-btn-prev{
			width: 65px;
			height: 65px;
			position: absolute;
			left: 0;
			top: 5px;
			opacity: 1;
			transition: .3s ease;

			display: none;
			&:before{
				content: '';
				width: 22px;
				height: 22px;
				border-top: 2px solid #464646;
				border-left: 2px solid #464646;
				transform: rotate(-45deg);
				position: absolute;
				bottom: 0;top: 0;right: 0;left: 0;
				margin: auto;
			}
			&:hover{
				opacity: .8;
			}
		}
		.tab-btn-next{
			width: 65px;
			height: 65px;
			position: absolute;
			right: 0;
			top: 5px;
			opacity: 1;
			transition: .3s ease;

			display: none;
			&:before{
				content: '';
				width: 22px;
				height: 22px;
				border-top: 2px solid #464646;
				border-right: 2px solid #464646;
				transform: rotate(45deg);
				position: absolute;
				bottom: 0;top: 0;right: 0;left: 0;
				margin: auto;
			}
			&:hover{
				opacity: .8;
			}
		}
	}
	// .roster--tab{
	// 	margin: 50px 0;
	// 	li{
	// 		@extend .barlow;
	// 		font-weight: 500;
	// 		font-size: 24px;
	// 		letter-spacing: 0;
	// 		opacity: .5;
	// 		min-width: 200px;
	// 		text-align: center;
	// 		margin-right: 10px;
	// 		padding: 10px 0;
	// 		position: relative;
	// 		cursor: pointer;
	// 		&.active{
	// 			opacity: 1;
	// 			&:before{
	// 				transform: scaleX(1);
	// 			}
	// 		}
	// 		&:last-child{margin-right:0;}
	// 		&:before{
	// 			content:'';
	// 			height: 2px;
	// 			width: 100%;
	// 			background-color: #FF8C00;
	// 			position: absolute;
	// 			bottom: 0;
	// 			left:0;
	// 			transform: scaleX(0);
	// 			transition: .3s ease;
	// 		}
	// 		.flag-icon{
	// 			width: 26px;
	// 			height: 26px;
	// 			background-size: cover;
	// 			border-radius: 100%;
	// 			box-shadow: inset 0px 0px 8px 0 rgba(0, 0, 0, 0.25);
	// 		}
	// 	}
	// }
}

@media screen and (max-width: 768px){
	.page--division{
		.introduction{
			.title{font-size: 24px;text-transform: capitalize;}
			.description{
				p{font-size: 12px;}
			}
			.row .col.m6{
				width: susy-span(12 of 12);
				padding: 0;
				margin-bottom: 10px;
				&:last-child{
					margin-bottom: 0;
				}
			}
		}
		.roster{
			.show-m{display: block !important;}
			.title{font-size: 26px;text-transform: capitalize;}
			.subtitle{font-size: 14px;}
			.roster--profile{
				h6{
					font-size: 14px;
					margin-bottom: 10px;
				}
			}
		}
		.mainbanner_h140.hidden-lg{
			height: 180px;
		}
	}
}

@media screen and (max-width: 480px){
	.page--division{
		.roster{
			.show-m{display: block !important;}
			.title{font-size: 24px;text-transform: capitalize;}
			.subtitle{font-size: 12px;}
			.roster__list--m{
				.roster__li{
					.roster__li__bottom{
						padding-top: 11%;
					}
				}
			}
			.roster--profile{
				h6{
					font-size: 12px;
				}
			}
		}
	}
}

@media screen and (min-width: 520px){
	.mainbanner_h140{
		height: 280px;
	}
}