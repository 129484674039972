.ba-tab{
	margin: 60px 0 90px;
	.ba-tab-button{
		margin: 0 0 16px;
		ul{
			display: flex;
			margin: 0;
			li{
				flex-shrink: 0;
				min-width: 190px;
				padding: 10px 20px 11px;
				background-color: #f3f3f3;
				color: rgba(#464646, .7);
				font-weight: 600;
				letter-spacing: 0.5px;
				font-size: 18px;
				text-align: right;
				margin-right: 15px;
				transform: skewX(-7deg);
				display: flex;
				justify-content: flex-end;
				align-items: center;
				cursor: pointer;
				position: relative;
				transition: .3s ease;
				&:hover{
					background-color: darken(#f3f3f3, 8%);
				}
				&:last-child{
					margin-right: 0;
				}
				&:before{
					content: '';
					width: 16px;
					height: 16px;
					border-top: solid 8px #fff;
					border-left: solid 8px #fff;
					border-right: solid 8px transparent;
					border-bottom: solid 8px transparent;
					position: absolute;
					top: -1px;
					left: -1px;
				}
				&:after{
					content: '';
					width: 16px;
					height: 16px;
					border-bottom: solid 8px #fff;
					border-right: solid 8px #fff;
					border-left: solid 8px transparent;
					border-top: solid 8px transparent;
					position: absolute;
					bottom: -1px;
					right: -1px;
				}
				&.active{
					background-color: #ea8e12;
					color: #fff;
					cursor: default;
					pointer-events: none;
					&:hover{
						background-color: #ea8e12;
					}
				}
			}
		}
	}
}

.ba-list{
	.ba-list-item{
		position: relative;
		min-height: 310px;
		margin-bottom: 16px;
		overflow-x: hidden;
		&:last-child{
			margin-bottom: 0;
		}
		.title{
			font-weight: 600;
			font-size: 44px;
			letter-spacing: 0;
			font-style: italic;
			margin-bottom: 5px;
		}
		.subtitle{
			font-weight: 600;
			font-size: 16px;
			letter-spacing: 0;
			font-style: italic;
		}
		.desc{
			margin-top: 25px;
			font-family: 'opensans-regular';
			p{
				font-family: inherit;
				line-height: 19px;
				max-height: 95px;
				overflow: hidden;
				display: block;
			}
		}
		.socmed{
			margin-top: 35px;
			display: flex;
			.socmed__li{
				flex-shrink: 0;
				&:last-child{
					margin-right: 0;
				}
				.socmed__a{
					padding-right: 27px;
					transition: .3s ease;s
					i{
						position: absolute;
						left: 0;
					}
					&:hover{
						opacity: .8;
					}
				}
			}
		}
		.ident{
			@extend .barlow;
			font-weight: 400;
			font-style: italic;
			margin: 5px -17px 0 -16px;
			li{
				padding: 3px 17px 3px 16px;
				position: relative;
				&:after{
					content: '';
					width: 1px;
					height: 100%;
					background-color: #fff;
					position: absolute;
					right: 0;
					top: 0;
				}
				&:last-child{
					&:after{
						display: none;
					}
				}
			}
		}
		.img{
			width: 100%;
			height: 100%;
			position: relative;
			.photo{
				position: relative;
				z-index: 1;
				display: inline-block;
			}
			.photo-shadow{
				position: absolute;
				z-index: 0;
				width: 325px;
				height: 310px;
				bottom: 0;
				opacity: .12;
			}
		}
		.copy{
			position: relative;
			z-index: 1;
		}
		&.temp-right{
			background-image: linear-gradient(55deg, #ea8e12 5%, #ffc458 91%);
			color: #fff;
			&:before{
				content: '';
				width: 40%;
				height: 30px;
				background-color: #fff;
				position: absolute;
				top: -1px;
				right: -8px;
				z-index: 0;
				transform: skewX(30deg);
			}
			&:after{
				content: '';
				width: 28px;
				height: 28px;
				border-bottom: solid 14px #fff;
				border-right: solid 14px #fff;
				border-left: solid 14px transparent;
				border-top: solid 14px transparent;
				position: absolute;
				bottom: 0px;
				right: 0px;
				z-index: 1;
			}	
			.copy{
				padding: 30px 0 30px 30px;
			}
			.glyph-right{
				background:url(/images/ico/ico-glyph-right.svg) no-repeat;
				@include sprite(327px, 310px, 0);
				position: absolute;
				top: 0;
				right: 0;
				display: inline-block;
			}
			.img{
				display: flex;
				justify-content: flex-end;
				align-items: flex-end;
				.photo-shadow{
					left: -35%;
				}
			}
		}
		&.temp-left{
			background-image: linear-gradient(-55deg, #ea8e12 5%, #ffc458 91%);
			color: #fff;
			&:before{
				content: '';
				width: 40%;
				height: 30px;
				background-color: #fff;
				position: absolute;
				top: -1px;
				left: -8px;
				z-index: 0;
				transform: skewX(-30deg);
			}
			&:after{
				content: '';
				width: 28px;
				height: 28px;
				border-bottom: solid 14px #fff;
				border-left: solid 14px #fff;
				border-right: solid 14px transparent;
				border-top: solid 14px transparent;
				position: absolute;
				bottom: 0px;
				left: 0px;
				z-index: 1;
			}
			.copy{
				padding: 30px 30px 30px 0;
			}
			.glyph-left{
				background:url(/images/ico/ico-glyph-left.svg) no-repeat;
				@include sprite(327px, 310px, 0);
				position: absolute;
				top: 0;
				left: 0;
				display: inline-block;
			}
			.img{
				display: flex;
				justify-content: flex-start;
				.photo-shadow{
					right: -35%;
				}
			}
		}
	}
}
.ba-list-mobile{
	.ba-list-item-mobile{
		position: relative;
		color: #fff;
		background-image: linear-gradient(45deg, #ea8e12 50%, #ffc458 100%);
		margin-bottom: 15px;
		overflow-x: hidden;
		&:last-child{
			margin-bottom: 0;
		}
		&:before{
			content: '';
			width: 80%;
			height: 25px;
			background-color: #fff;
			position: absolute;
			top: -1px;
			right: -8px;
			z-index: 0;
			transform: skewX(30deg);
		}
		&:after{
			content: '';
			width: 30px;
			height: 30px;
			border-bottom: solid 15px #fff;
			border-right: solid 15px #fff;
			border-left: solid 15px transparent;
			border-top: solid 15px transparent;
			position: absolute;
			bottom: 0px;
			right: 0px;
			z-index: 1;
		}
		.img{
			text-align: left;
			position: relative;
			.photo{
				display: inline-block;
				position: relative;
				z-index: 3;
			}
			.photo-shadow{
				position: absolute;
				bottom: 0;
				right: 0;
				z-index: 0;
				opacity: 0.12;
			}
			.line-separator{
				overflow: hidden;
				width: 100%;
				height: 50px;
				position: absolute;
				bottom: 1px;
				left: 0;
				&:before{
					content: '';
					width: calc(100% - 25px);
					height: 2px;
					background-color: #fff;
					position: absolute;
					bottom: 0;
					left: 0;
				}
				&:after{
					content: '';
					width: 50px;
					height: 50px;
					border: 2px solid #fff;
					background-color: #f1a12a;
					transform: rotate(45deg);
					position: absolute;
					right: -25px;
					bottom: -25px;
				}
			}
		}
		.copy{
			padding: 26px 20px;
			.title{
				font-weight: 600;
				font-size: 24px;
				letter-spacing: 0;
				font-style: italic;
				margin-bottom: 5px;
				line-height: 29px;
			}
			.subtitle{
				font-weight: 600;
				font-size: 14px;
				line-height: 17px;
				letter-spacing: 0;
				font-style: italic;
			}
			.desc{
				margin-top: 30px;
				font-family: 'opensans-regular';
				position: relative;
				&.expand{
					p{
						max-height: 500px;
					}
				}
				p{
					font-family: inherit;
					line-height: 22px;
					display: block;
					max-height: 44px;
					overflow: hidden;
					transition: .3s ease;
				}
				.comment{
					max-height: unset;
				}
			}
			.socmed{
				margin-top: 35px;
				.socmed__ul{
					display: flex;
					margin-top: 5px;
				}
				.socmed__li{
					flex-shrink: 0;
					&:last-child{
						margin-right: 0;
						.socmed__a{
							padding-right: 0;
						}
					}
					.socmed__a{
						padding-right: 12px;
						transition: .3s ease;
						i{
							width: 36px;
							height: 36px;
							background-size: 36px;
						}
						&:hover{
							opacity: .8;
						}
					}
				}
			}
		}
		.ident{
			@extend .barlow;
			font-weight: 400;
			font-style: italic;
			margin: 20px -17px 0 -16px;
			li{
				padding: 3px 17px 3px 16px;
				position: relative;
				&:after{
					content: '';
					width: 1px;
					height: 100%;
					background-color: #fff;
					position: absolute;
					right: 0;
					top: 0;
				}
				&:last-child{
					&:after{
						display: none;
					}
				}
				b{
					display: block;
				}
			}
		}
		.glyph-right{
			background:url(/images/ico/ico-glyph-right.svg) no-repeat;
			@include sprite(327px, 310px, 0);
			position: absolute;
			top: 0;
			right: 0;
			left: 0;
			margin: auto;
			z-index: 1;
			display: inline-block;
		}
		.glyph-left{
			background:url(/images/ico/ico-glyph-left.svg) no-repeat;
			@include sprite(327px, 310px, 0);
			position: absolute;
			top: 0;
			left: 0;
			margin: auto;
			z-index: 1;
			display: inline-block;
		}
		.link{
			position: relative;
			padding-right: 10px;
			text-decoration: none;
			@extend .barlow;
			font-size: 14px;
			color: #464646;
			letter-spacing: 0.25px;
			transition: .3s ease;
			display: inline-block;
			&:after{
				content: '';
				width: 5px;
				height: 5px;
				border-top: 1px solid #464646;
				border-right: 1px solid #464646;
				transform: rotate(45deg);
				position: absolute;
				right: 0;
				top: 5px;
				transition: .3s ease;
			}
			&.see-more{
				background-color: #EB9115;
				padding-left: 12px;
				position: absolute;
				right: 0;
				bottom: 2px;
				&:before{
					content: '...';
					position: absolute;
					left: 0;
					color: #fff;
				}
				&:after{
					top: 8px;
				}
			}
			&.show-less{
				display: none;
				&:after{
					transform: rotate(-45deg);
					top: 9px;
				}
			}
		}
		.comment{
			.morelink{
				color: #464646;
				&:after{
					border-top: 1px solid #464646;
					border-right: 1px solid #464646;
				}
			}
		}
	}	
}

@media only screen and (min-width: 560px)
and (max-width: 768px)
and (orientation: portrait){
	.ba-list-mobile{
		.ba-list-item-mobile{
			.img{
				.line-separator{
					&:after{
						background-color: #F5A935;
					}
				}
			}
		}	
	}
}

@media only screen and (max-width: 400px){
	.ba-list-mobile{
		.ba-list-item-mobile{
			.img{
				.photo-shadow{
					max-width: 240px;
				}
			}
		}
	}
}

@media only screen and (max-width: 350px){
	.ba-list-mobile{
		.ba-list-item-mobile{
			.img{
				.photo-shadow{
					max-width: 210px;
				}
			}
		}
	}
}