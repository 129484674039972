.footer{
	background-color: #383838;
	color: #fff;
	position: relative;
    z-index: 1;
	.container1280{
		padding: 20px 0;
		min-height: 175px;
	}
	.copyright{
		background-color: #2e2e2e;
		padding: 5px 0;
	}
	.navbar{
		li{
			a{
				margin: 5px 0;
				opacity: .7;
				display: inline-block;
				&:hover{
					opacity: 1;
				} 
			}
		}
	}
	.socmed{
		li{
			a{
				margin: 0 14px 0 0;
			}
		}
	}
}

@media screen and (max-width: 768px){
	.footer{
		.content{
			padding: 10px 42px;
		}
		.row{
			.col{
				&.col-logo{
					width: 100% !important;
					margin-bottom:20px;
					.logo{
						img{width: 80px;}
					}
				}
				&.m2{
					width: susy-span(6 narrow of 12 narrow);
					margin-right: gutter(of 12);
					&:nth-child(2){
						margin-right: 8%;
					}
					&:nth-child(3){
						width: susy-span(5 narrow of 12 narrow);
					}
				}
			}
		}
	}
}

@media screen and (max-width: 480px){
	.footer{
		h5{
			font-size: 14px;
		}
		.navbar li a{font-size: 12px;}
	}
}