.header{
	&.headroom {
		will-change: transform;
		transition: transform .4s ease;
	}
	&.headroom--pinned {
		transform: translateY(0%);
	}
	&.headroom--unpinned {
		transform: translateY(-50px);
		.header__left{
			.logo{
				align-items: flex-end;
				img{
					max-height: 45px;
					transition: .4s ease;
					transform-origin: center bottom;
				}
			}
		}
		.header__right{
			.header--sponsor{
				.sponsor--slide{
					align-items: flex-end;
				}
				img{
					max-height: 60px;
					transition: .4s ease;
					transform-origin: right bottom;
				}
			}
		}
	}
}

.header-mobile{
	&.headroom {
		will-change: transform;
		transition: transform .5s ease;
	}
	&.headroom--pinned {
		transform: translateY(0%);
	}
	&.headroom--unpinned {
		transform: translateY(-57px);
	}
}

@media screen and (min-width: 769px)
and (max-width: 1279px){
	.header{
		.header__right{
			.header--sponsor{
				img{
					transition: .3s ease;
				}
			}
		}
		&.headroom--pinned{
			.header__right{
				.header--sponsor{
					img{
						visibility: visible;
						opacity: 1;
					}
				}
			}
		}
		&.headroom--unpinned {
			.header__right{
				.header--sponsor{
					img{
						visibility: hidden;
						opacity: 0;
					}
				}
			}
		}
	}
}