.page--contact {
  .chosen-container {
    height: 45px;
    border-radius: 2px;
    width: 100% !important;
    display: block;
  }

  .chosen-container .chosen-results {
     background: #fff;
     margin: 0;
     font-style: 'rubick', sans-serif;
     font-size: 18px;
     letter-spacing: 0;
     line-height: 22px;
     color: #383838;
     padding: 0;
     li {
      padding: 10px 20px 13px 20px;
      color: rgba(#383838, .7);
      &.highlighted {
        background-color: #FEF6E2 !important;
        background-image: none;
        color: #383838;
      }
     }
  }

  .chosen-container-active .chosen-single {
    box-shadow: none;
  }

  .chosen-container-single .chosen-drop {
    border-radius: 2px;
    border: 1px solid rgba(56, 56, 56, 0.25);
    margin-top: 2px;
  }

  .chosen-container-single .chosen-single {
    height: 44px;
    background-image: linear-gradient(-180deg, #f9f9f9 0%, #D9D9D9 100%);
    border: 1px solid rgba(56, 56, 56, 0.25);
    border-radius: 2px;
    padding-left: 20px;
    padding-right: 20px;
    color: #383838;
    box-shadow: none;
  }

  .chosen-container-single .chosen-single div {
    top: 0;
    bottom: 0;
    width: 24px;
    b {
      background-repeat: no-repeat;
      background-position: 0% 0%;
      background-image: url('/images/contact/arrow-select.png') !important;
      margin-top: 18px;
      width: 11px;
      height: 9px;
      background-color: transparent;
      background-size: cover !important;
    }
  }

  .chosen-container-active.chosen-with-drop .chosen-single div b {
    background-repeat: no-repeat !important;
    background-position: 0% 0% !important;
  }

  .chosen-container-single .chosen-single span {
    height: 42px;
    display: inline-block;
    font-size: 18px;
    letter-spacing: 0;
    line-height: 22px;
    margin-right: 0;
    padding-top: 9px;
  }

  
}

@media(max-width: 768px) {
  .page--contact {
    .chosen-container {
      height: 35px;
    }

    .chosen-container-single .chosen-single {
      height: 34px;
      padding-left: 15px;
      padding-right: 15px;
      span {
        padding-top: 6px;
        font-size: 14px;
        height: 34px;
      }
      div {
        b {
          
        }
      }
    }
  }
}