.page--matches{
	background-color: #f6f6f6;
	.pt_20{padding-top: 20px;}
	.pb_25{padding-bottom: 25px;}
	.mb_20{margin-bottom: 20px;}
	.row{
		margin-left: -10px;
		margin-right: -10px;
		.col{
			padding-left: 10px;
			padding-right: 10px;
		}
	}
	.match-detail{
		padding: 20px;
		.match--table{
			.table--list{
				.table--list-right{
					.row{
						margin: 0;
					}
				}
			}
		}
	}
}

.upcoming-match{
	.match-detail{
		padding: 4px;
		.match-detail--header{
			padding: 8px 0;
			h3{
				@extend .barlow;
				font-weight: 600;
				font-size: 24px;
				letter-spacing: 0.5px;
			}
		}
		.match--table{
			margin: 4px 0 0;
			.table--list{
				height: 64px;
				.table--list-right{
					&:after{
						background-size: 105px;
						height: 63px;
					}
					h3{
						font-size: 14px;
						letter-spacing: 0.17px;
					}
					.team-vs{
						font-size: 12px;
						letter-spacing: 0.25px;
					}
					.team-name{
						font-size: 14px;
						letter-spacing: 0.29px;
					}
					.time{
						font-size: 12px;
						line-height: 18px;
					}
					.live{
						font-size: 10px;
					}
				}
			}
		}
	}
	.btn--see-all-container{
		padding: 10px 0;
		.btn--see-all{
			@extend .barlow;
			font-weight: 600;
			font-size: 18px;
			color: #ff8c00;
			letter-spacing: 0.38px;
			text-transform: uppercase;
			transition: .3s ease;
			position: relative;
			padding-right: 10px;
			&:after{
				content: '';
				width: 7px;
				height: 7px;
				border-top: 2px solid #ff8c00;
				border-left: 2px solid #ff8c00;
				position: absolute;
				transform: rotate(135deg);
				top: 8px;
				bottom: 0;
				right: 0;
			}
			&:hover{
				color: darken(#ff8c00, 5%);
			}
		}
	}
}


.matches--detail{
	.detail--banner{
		position: relative;
		height: 174px;
		display: flex;
		align-items: center;
		justify-content: center;
		.bg{
			position: absolute;
			width: 100%;
			height: 100%;
			img{
				position:absolute;
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
		.banner-title{
			position: relative;
			.title-img,
			.title-text{
				display: inline-block;
				vertical-align: middle;
			}
			.title-img{
				margin-right: 15px;
				img{display: block;}
			}
			.title-text{
				@extend .barlow;
				font-weight: 700;
				font-size: 32px;
				letter-spacing: 0.62px;
				text-shadow: 0 1px 1px rgba(255,255,255,0.50);
			}

		}
	}
	.detail--info{
		background-color: #363636;
		padding: 13px 0 14px;
		text-align: center;
		p{
			@extend .barlow;
			font-size: 14px;
			color: #fff;
			letter-spacing: 0.5px;
			margin: 0;
			span{
				@extend .rubick;
				margin: 0 8px;
			}
		}
	}
	.detail--hth{
		background-color: #fff;
		position: relative;
		overflow: hidden;
		height: 120px;
		display: flex;
		align-items: center;
		justify-content: center;
		&:before{
			content: '';
			// background-image: radial-gradient(50% 55%, rgba(255,255,255,0.50) 50%, rgba(247,184,28,0.50) 100%);
			background-image: radial-gradient(24% 35%, rgba(255, 255, 255, 0.5) 50%, rgba(247, 184, 28, 0.4) 173%);
			width: 100%;
			height: 300%;
			position:absolute;
			top:0;
			left:0;
			bottom: 0;
			margin: auto;
		}
		.row{position: relative;width: 100%;}
		.team{}
		.team-copy{
			@extend .barlow;
			h3{
				font-weight: 600;
				font-size: 24px;
				letter-spacing: 0.25px;
				margin-bottom: 8px;
			}
			h5{
				font-size: 12px;
				color: #757575;
				letter-spacing: 0.43px;
			}
			.flag-icon{vertical-align: middle;}
		}
		.team-copy,
		.team-logo{
			display: inline-block;
			vertical-align: middle;
		}
		.right{
			.team-logo{
				margin-left: 30px;
			}
			.flag-icon{margin-left:6px;}
		}
		.left{
			.team-logo{
				margin-right: 30px;
			}
			.flag-icon{margin-right:6px;}
		}
		.match-result{
			@extend .barlow;
			font-size: 48px;
			letter-spacing: 0.5px;
			font-weight: 600;
			span{
				display: inline-block;
				vertical-align: top;
				font-size: 40px;
				color: #757575;
			}
		}
		.match-rank{
			span{
				display: block;
				@extend .barlow;
				font-size: 16px;
				letter-spacing: 0.5px;
			}
			.big{
				font-size: 48px;
				font-weight: 600;
				line-height: 44px;
			}
		}
		.match-round{
			@extend .barlow;
			font-weight: 600;
			font-size: 24px;
			letter-spacing: 0.75px;
		}
	}
	.detail--table{
		padding: 40px 22px;
		// table{
		// 	width: 100%;
		// 	@extend .barlow;
		// 	thead{
		// 		background-color: #f0f0f0;
		// 		border-bottom: 1px solid #f7b81c;
		// 	}
		// 	tbody{
		// 		background-color: #f8f8f8;
		// 		td{
		// 			border-bottom: 1px solid #cbcbcb;
		// 		}
		// 	}
		// 	th{
		// 		padding: 16px 20px;
		// 		font-weight: 700;
		// 		font-size: 18px;
		// 		letter-spacing: 0;
		// 		text-align: left;
		// 	}
		// 	td{
		// 		padding: 11px 20px 13px;
		// 		font-weight: 600;
		// 		font-size: 18px;
		// 		letter-spacing: 0.45px;
		// 		text-align: left;
		// 	}
		// 	.flag-icon{
		// 		width: 1em;
		// 		display: inline-block;
		// 		vertical-align: middle;
		// 	}
		// 	.name{
		// 		margin-right: 7px;
		// 	}
		// 	i.capt{
		// 		margin-right: 8px;
		// 		display: inline-block;
		// 		vertical-align: middle;
		// 		width: 16px;
		// 		height: 16px;
		// 		background-image: url(/images/ico/ico-capt.svg);
		// 		background-repeat: no-repeat;
		// 		background-position: center;
		// 	}
		// }
		.table-team{
			@extend .barlow;
			.table-team--header{
				background-color: #f0f0f0;
				border-bottom: 1px solid #f7b81c;

				display: flex;
				justify-content: space-between;
				align-items: center;

				position: relative;
				h3{
					padding: 16px 20px;
					font-weight: 700;
					font-size: 18px;
					letter-spacing: 0;
					text-align: left;
				}
				.btn--table-collapse{
					position: absolute;
					top:0;bottom:0;right: 15px;
					margin: auto;
				}
			}
			.table-team--body{
				background-color: #f8f8f8;
				.table-team--body-row{
					border-bottom: 1px solid #cbcbcb;
					padding: 11px 20px 13px;
					font-size: 18px;
					letter-spacing: 0.45px;
					text-align: left;
					.flag-icon{
						width: 1em;
						display: inline-block;
						vertical-align: middle;
					}
					.name{
						margin-right: 7px;
					}
					i.capt{
						margin-right: 8px;
						display: inline-block;
						vertical-align: middle;
						width: 16px;
						height: 16px;
						background-image: url(/images/ico/ico-capt.svg);
						background-repeat: no-repeat;
						background-position: center;
					}
				}
			}
		}
		.detail--video{
			margin-top: 40px;
			.detail--video--header{
				@extend .barlow;
				background-color: #f0f0f0;
				border-radius: 2px 2px 0 0;
				padding: 16px 20px;
				h3{
					font-weight: 600;
					font-size: 24px;
					letter-spacing: 0.5px;
				}
				.video-channel{
					font-size: 14px;
					color: #757575;
					letter-spacing: 0.17px;
					transition: .3s ease;

					display: flex;
					align-items: center;
					&:hover{
						color: darken(#757575, 5%);
					}
					i{margin-right: 7px;}
					.ico-youtube{
						width: 18px;
						height: 13px;
						background-image: url(/images/ico/ico-youtube.svg);
						background-repeat: no-repeat;
						background-position: center;
						display: inline-block;
						vertical-align: top;
						position: relative;
						top: 0;
					}
					.ico-facebook{
						width: 26px;
						height: 26px;
						background-image: url(/images/ico/ico-facebook.svg);
						background-repeat: no-repeat;
						background-position: center;
						display: inline-block;
						vertical-align: top;
						position: relative;
						top: 0;
					}
					.ico-twitch{
						width: 26px;
						height: 26px;
						background-image: url(/images/ico/ico-twitch.svg);
						background-repeat: no-repeat;
						background-position: center;
						display: inline-block;
						vertical-align: top;
						position: relative;
						top: 0;
					}
				}
			}
			.detail--video--body{
				position: relative;
				.video__wrapper{
					left: 0;
					width: 100%;
					height: 0;
					position: relative;
					padding-bottom: 56.2493%;
					iframe{
						border: 0;
						top: 0;
						left: 0;
						width: 100% !important;
						height: 100% !important;
						position: absolute;
					}
				}
				.video--placeholder{
					padding: 28px 0 32px;
					background-color: #f0f0f0;
					border-top: 1px solid #cbcbcb;
					img{
						margin: auto;
					}
				}
			}
		}
	}
	.detail--control-page{
		@extend .barlow;
		box-shadow: 0 -1px 3px 0 rgba(0,0,0,0.15);
		background-color: #fff;
		.row{
			.col{
				padding: 16px 20px;
				border-right: 1px solid #cbcbcb;
				&:last-child{
					border-right:0;
				}
			}
		}
		.control-page--prev{
			.link{
				margin-right: 8px;
				position: relative;
				padding-left: 15px;
				font-weight: 500;
				&:before{
					content: '';
					width: 8px;
					height: 8px;
					border-top: 2px solid #ff8c00;
					border-left: 2px solid #ff8c00;
					transform: rotate(-45deg);
					position: absolute;
					left: 0;
					top: 4px;
					transition: .2s ease;
				}
			}
			&:hover{
				.link{
					&:before{
						left: -3px;
					}
				}
			}
		}
		.control-page--next{
			.link{
				margin-left: 8px;
				position: relative;
				padding-right: 15px;
				font-weight: 500;
				&:before{
					content: '';
					width: 8px;
					height: 8px;
					border-top: 2px solid #ff8c00;
					border-right: 2px solid #ff8c00;
					transform: rotate(45deg);
					position: absolute;
					right: 0;
					top: 4px;
					transition: .2s ease;
				}
			}
			&:hover{
				.link{
					&:before{
						right: -3px;
					}
				}
			}
		}
		.link{
			font-size: 14px;
			color: #ff8c00;
			letter-spacing: 0.29px;
			text-transform: uppercase;
			transition: .3s ease;
			&:hover{
				color: darken(#ff8c00, 5%);
			}
		}
		.round{
			font-size: 14px;
			letter-spacing: 0.29px;
			font-weight: 500;
		}
		.match{
			display: flex;
		}
		.match-result{
			margin-top: 8px;
			font-size: 18px;
			.score{margin-left: 12px;}
		}
		.team{
			display: flex;
			align-items: center;
		}
		.team-vs{
			margin: 0 8px;
			color: rgba(#363636, 0.5);
			letter-spacing: 0.25px;
			font-size: 12px;
		}
		.team-name{
			font-weight: 600;
			letter-spacing: 0.38px;
		}
		.team-logo{margin-right: 6px;}
	}
	.detail--battle-format{
		background-color: #363636;
		padding: 13px 0 14px;
		border-top: 1px solid rgba(117,117,117,0.5);
		@extend .barlow;
		letter-spacing: 0.35px;
		ul{
			margin: 0;
			display: flex;
			align-items: center;
			justify-content: center;
			li{
				text-align: center;
				min-width: 100px;
				margin: 0 5px;
			}
		}
		.format--label{
			font-size: 14px;
			color: #757575;
			margin-bottom: 2px;
			display: block;
		}
		h6{
			font-size: 14px;
			font-weight: 600;
			color: #fff;
		}
	}
}

.btn--table-collapse{
	width: 8px;
	height: 8px;
	border-top: 2px solid #ff8c00;
	border-left: 2px solid #ff8c00;
	transform: rotate(45deg);
	transition: .3s ease;
	&.collapse{
		transform: rotate(225deg);
	}
}

@media screen and (max-width: 768px){
	.page--matches{
		.xs-padding0{padding: 0;}
		.match-detail{overflow: hidden;}

		.matches--detail{
			.detail--banner{
				height: 157px;
				.banner-title{
					.title-img{
						display: block;
						margin: 0 0 15px;
						img{margin: auto;}
					}
					h1{font-size: 18px;}
				}
			}
			.detail--info{
				padding: 8px 0 9px;
				p{font-size: 12px;}
			}
			.detail--hth{
				display: block;
				height: auto;
				padding: 14px 0;
				.team-logo{
					margin: 0;
					img{
						width: 50px;
					}
				}
				.match-round{font-size: 14px;}
				.match-rank{
					span{font-size: 12px;}
					.big{font-size: 36px;line-height: 34px;}
				}
				.match-result{
					font-size: 36px;
					span{font-size: 30px;}
				}
				.team-copy{
					h3{
						margin-bottom: 2px;
						font-size: 18px;
					}
				}
				.right{
					.team{
						display: flex;
						flex-direction: column-reverse;
						align-items: flex-end;
						justify-content: center;
					}
				}
			}
		}
		.detail--table{
			padding: 20px 15px;
			.detail--video{
				margin-top: 20px;
				.detail--video--header{
					display: block;
					padding: 12px 10px;
					h3{margin-bottom: 4px;}
				}
			}
			.table-team{
				.table-team--header{
					h3{
						padding: 10px;
						font-size: 16px;
					}
				}
				.table-team--body{
					.table-team--body-row{
						padding: 11px 10px 9px;
						.name{
							font-size: 12px;
							b{font-size: 18px;}
						}
					}
				}
			}
		}
		.detail--control-page{
			.control-page--next,
			.control-page--prev{
				.link{
					font-size: 0;
					&:before{
						top: -4px;
					}
				}
				.team{
					.team-name{display: none;}
				}
			}
			.control-page--prev{
				&:hover{
					.link{
						&:before{
							left: 0;
						}
					}
				}
			}
			.control-page--next{
				&:hover{
					.link{
						&:before{
							right: 0;
						}
					}
				}
			}
		}
		.match-detail{
			.match--table{
				.table--list{
					.table--list-right{
						.col{
							&:nth-child(2){
								text-align: right;
							}
						}
					}
				}
			}
		}
	}
}
