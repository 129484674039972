.page--academy{
	width: 100%;
	overflow-x: hidden;
	.mainbanner{
		position: relative;
		z-index: 2;
	}
	.introduction{
		padding: 120px 0 250px;
		transform: skewY(-5deg);
		margin-top: -70px;
		position: relative;
		z-index: 1;
		.content{
			.img{
				img{display: inline-block;max-width: 288px;}
			}
			.desc{
				font-size: 16px;
			}
		}

		[class*="container"]{
			transform: skewY(5deg);
		}
	}
	.package{
		width: 100%;
		background-color: #fcba2a;
		margin-top: -5%;
		padding-top: 0;
		padding-bottom: 6%;
		position: relative;
		.video-wrapper{
			position: relative;
			z-index: 2;
			top: -80px;
			z-index: 1;
			left: 0;
			right: 0;
			margin: auto;

			display: flex;
			justify-content: center;
			.link-video{
				box-shadow: 0 12px 24px 0 rgba(19,39,58,0.5);
				position: relative;
				width: 640px;
				max-width: 100%;
				padding-bottom: 23%;
				overflow: hidden;
				// &:after{
				// 	content: '';
				// 	width: 131px;
				// 	height: 131px;
				// 	background-image: url('/images/academy/play.svg');
				// 	position: absolute;
				// 	top:0;right:0;bottom:0;left:0;
				// 	margin: auto;
				// }
				&:after{
					content: '';
					width: 68px;
					height: 68px;
					background-image: url('/images/academy/ico-play-def.svg');
					background-repeat: no-repeat;
					background-position: center;
					background-size: cover;
					position: absolute;
					top:0;right:0;bottom:0;left:0;
					margin: auto;
					border-radius: 100%;
					box-shadow: 0 0 0 0 rgba(252, 186, 42, 1);
					animation: pulse-orange 2s infinite;
				}
				img{
					position: absolute;
					width: 100%;
					top: 0;
					left: 0;
					bottom: 0;
					right: 0;
					margin: auto;
					object-fit: cover;
				}
			}
		}
		.content{
			.title{
				margin-bottom: 26px;
			}
			.desc{
				font-size: 16px;
				margin-bottom: 50px;
				p{
					font-size: inherit;
				}
			}
			.info-launch{
				// background-image: url('/images/academy/launch-banner.svg');
				// background-position: center;
				// background-size: cover;
				// background-repeat: no-repeat;
				width: 100%;
				margin: 0 auto;
				color: #fff;
				display: flex;
				align-content: center;
				justify-content: center;
				flex-direction: column;
				h2,h3{
					color: inherit;
				}
				.info-launch-inner{
					height: 100%;
					width: 80%;
					margin: auto;
					background-color: #ff6700;
					display: flex;
					align-items: center;
					justify-content: center;
					flex-direction: column;
					position: relative;
					transform: skewY(-3deg);
					padding: 15px 0;
					&:before,
					&:after{
						transform: skewY(-3deg);
					}
					&:before{
						content: '';
						width: 0;
						height: 0;
						border-top: 48px solid transparent;
						border-right: 27px solid #ff6700;
						border-bottom: 48px solid transparent;
						position: absolute;
						top: 0;
						left: -27px;
					}
					&:after{
						content: '';
						width: 0;
						height: 0;
						border-top: 48px solid transparent;
						border-left: 27px solid #ff6700;
						border-bottom: 48px solid transparent;
						position: absolute;
						top: 0;
						right: -27px;
					}
					h3,
					h2{
						transform: skewY(3deg);
					}
				}
			}
			.package-list{
				margin-top: 70px;
				.row{
					margin: 0 -10px;
					.col{
						padding: 0 10px;
					}
				}
				.package-list-item{
					text-align: left;
					background-color: #fff;
					height: 100%;
					position: relative;
					transform: skewY(-3deg);
					box-shadow: 0 4px 8px 0 rgba(0,0,0,0.15);
					transition: box-shadow .3s ease;

					// height: 630px;
					&:before{
						content: '';
						width: 64px;
						height: 64px;
						background-repeat: no-repeat;
						background-position: center;
						position: absolute;
						top:-20px;
						left: 28px;
						transform: skewY(3deg);
						z-index: 1;
					}
					.package-list-item-header{
						padding: 75px 20px 0;
						position: relative;
						background-color: rgba(255, 234, 229, 0);
						transition: background .2s ease;
						&:before{
							content: '';
							width: 100%;
							height: 30%;
							position: absolute;
							bottom: -10px;left:0;
							background-color: rgba(255, 234, 229, 0);
							transform: skewY(3deg);
							transition: background .2s ease;
						}
						.package-tag{
							font-size: 14px;
							color: #fff;
							font-weight: 600;
							position: absolute;
							top: 20px;
							right: -4px;
							z-index: 1;

							transform: skewY(3deg);
							h6{
								padding: 6px 15px;
								position: relative;
								z-index: 1;
							}
							&:after{
								content: '';
								width: 4px;
								height: 100%;
								transform: skewY(-55deg);
								z-index: 0;
								right: 0;
								bottom: -3px;
								position: absolute;
							}
							.ribbon{
								.overlay{
									width: 18px;
									height: 29px;
									background-color: rgba(#fff, 0.32);
									position: absolute;
									top:0;
									left: -17px;
									z-index: 1;
								}
								&:before{
									content: '';
									width: 0;
									height: 0;
									border-top: 29px solid transparent;
									border-bottom: 0px solid transparent;
									position: absolute;
									transform: rotate(-180deg);
									top: 0;
									left: -19px;
									margin: auto;
								}
								&:after{
									content: '';
									width: 0;
									height: 0;
									border-top: 0px solid transparent;
									border-bottom: 29px solid transparent;
									position: absolute;
									transform: rotate(180deg);
									bottom: 0px;
									left: -19px;
									margin: auto;
								}
							}
						}
					}
					.package-list-item-body{
						padding: 10px 20px 113px;
						// height: calc(100% - 100px);
						min-height: 483px;
						.btn-expand{
							color: #ff6700;
							font-size: 12px;
							letter-spacing: 0;
							padding-right: 10px;
							&:after{
								content: '';
								width: 5px;
								height: 5px;
								border-top: 1px solid #ff6700;
								border-left: 1px solid #ff6700;
								position: absolute;
								top: 5px;
								right: 0;
								transform: rotate(225deg);
							}
							&.active{
								&:after{
									top: 7px;
									transform: rotate(45deg);
								}
							}
						}
					}
					.inner-container{
						transform: skewY(3deg);
						height: 100%;
					}
					&.red{
						&:before{
							background-image: url('/images/academy/ico-luv.svg');
						}
						.package-tag{
							h6{
								background-color: #ff2d00;
							}
							&:after{
								background-color: rgb(173,30,0);
							}
							.ribbon{
								&:before,
								&:after{
									border-left: 20px solid #ff2d00;
								}
							}
						}
						.title{
							color: #ff2d00;
						}
						.info-registration{
							&:before{
								background-color: rgba(178,90,71,1);
							}
							&:after{
								background-color: rgba(178,90,71,1);
							}
							.title{
								background-color: #ff8166;
								color: #fff;
							}
						}
					}
					&.blue{
						&:before{
							background-image: url('/images/academy/ico-gun.svg');
						}
						.package-tag{
							h6{
								background-color: #0088ff;
							}
							&:after{
								background-color: rgb(0,54,102);
							}
							.ribbon{
								&:before,
								&:after{
									border-left: 20px solid #0088ff;
								}
							}
						}
						.title{
							color: #0088ff;
						}
						.info-tips{
							background-color: rgba(#0088ff, .10);
						}
						.info-registration{
							&:before{
								background-color: rgba(88,136,178,1);
							}
							&:after{
								background-color: rgba(88,136,178,1);
							}
							.title{
								background-color: #7fc3ff;
								color: #fff;
							}
						}
					}
					&:hover{
						&.red{
							box-shadow: 0 20px 32px 0 rgba(255,45,0,0.55);
							.package-list-item-header{
								background-color: rgba(255, 234, 229, 1);
								&:before{
									background-color: rgba(255, 234, 229, 1);
								}
							}
							.btn-wrapper{
								.btn-sign{
									&:before{
										box-shadow: 0 10px 12px 0 rgba(255, 45, 0, 0.75);
									}
								}
								.button-signup-bg{
									fill: #FF2D00;
								}
								.button-signup-text{
									fill: #fff;
								}
							}
						}
						&.blue{
							box-shadow: 0 20px 32px 0 rgba(0,136,255,0.55);
							.package-list-item-header{
								background-color: rgba(229,243,255,1);
								&:before{
									background-color: rgba(229,243,255,1);
								}
							}
						}
					}
					.title{
						margin-bottom: 23px;
					}
					.price{
						font-size: 12px;
						margin-bottom: 24px;
						// min-height: 63px;
						.price-row{
							margin-bottom: 7px;
						}
						.rp{
							vertical-align: top;
						}
						.big{
							font-size: 30px;
						}
						.rules{
							display: block;
							padding-left: 20px;
							font-size: 11px;
						}
					}
					.materi{
						max-height: 203px;
						// height: 240px;
						margin-bottom: 10px;
						overflow: hidden;
						transition: .5s ease;
						ul{
							margin: 0;
							li{
								padding: 0;
							}
						}
						&.expand{
							max-height: 1000px;
						}
					}
					.info-tips{
						font-size: 12px;
						color: rgba(#464646, .8);
						line-height: 18px;
						padding: 5px 20px 5px 10px;
						margin-bottom: 24px;
						i{
							flex-shrink: 0;
							margin-top: 8px;
							margin-right: 9px;
						}
					}
					.stickbot-content{
						position: absolute;
						width: 100%;
						bottom: 40px;
						left: 0;
						margin: 0;
						transform: skewY(3deg);
					}
					.info-registration{
						color: #fff;
						margin: 6px -6px 0;
						z-index: 1;
						position: relative;
						&:before{
							content: '';
							width: 6px;
							height: 100%;
							position: absolute;
							left: 0;
							top: -4px;
							transform: skewY(-55deg);
							z-index: 0;
						}
						&:after{
							content: '';
							width: 6px;
							height: 100%;
							position: absolute;
							right: 0;
							bottom: -4px;
							transform: skewY(-55deg);
							z-index: 0;
						}
						.title{
							position: relative;
							z-index: 4;
							display: block;
							width: 100%;
							height: 100%;
							padding: 12px;
							margin: 0;
						}
					}
				}
			}
			.btn-wrapper{
				display: flex;
				align-items: center;
				justify-content: center;
				position: relative;
				padding: 0 20px;
				svg{width: 100%;}
				.btn-sign{
					&:before{
						content: '';
						width: 97%;
						height: 57%;
						position: absolute;
						top: 0;
						right: 0;
						bottom: 0;
						left: 0;
						margin: auto;
						z-index: 0;
						box-shadow: 0 0 0 0 rgba(255, 45, 0, 0.55);
						transition: box-shadow .3s ease;
					}
					svg{
						position: relative;
						z-index: 1;
					}
					.button-signup-bg{
						// fill: #fff;
						fill: #FF2D00;
					}
					.button-signup-text{
						fill: #fff;
					}
					.button-signup-bg,
					.button-signup-text,
					.button-signup-border{
						transition: .3s ease;
					}
				}
			}

			.tab-game-class{
				.tab-button{
					ul{
						display: flex;
						justify-content: center;
						li{
							min-width: 262px;
							min-height: 66px;
							text-align: center;
							position: relative;
							margin-right: 16px;
							position: relative;
							display: flex;
							align-items: center;
							justify-content: center;
							cursor: pointer;
							&:last-child{
								margin-right: 0;
							}
							&:before{
								content: '';
								width: 100%;
								height: 100%;
								min-width: inherit;
								min-height: inherit;
								background-image: url('/images/academy/class-tab-default.svg');
								background-repeat: no-repeat;
								background-size: cover;
								position: absolute;
								top:0;left:0;
								z-index: 0;
							}
							&:after{
								content: '';
								width: 90%;
								height: 100%;
								box-shadow: 0 4px 8px 0 rgba(115,78,13,0);
								position: absolute;
								top: 0;
								left: 0;
								margin: auto;
								right: 0;
								z-index: -1;
							}
							&.active{
								&:before{
									background-image: url('/images/academy/class-tab-active.svg');
								}
								&:after{
									box-shadow: 0 4px 8px 0 rgba(115,78,13,0.5);
								}
								span{
									color: #fff;
								}
								.img{
									opacity: 0.24;
								}
							}
							&.mole{
								&.active{
									&:before{
										background-image: url('/images/academy/class-tab-mole.svg');
									}
									&:after{
										box-shadow: 0 4px 8px 0 rgba(21,59,82,0.5);
									}
								}
							}
							a{
								display: flex;
								justify-content: center;
								align-items: center;
								width: 100%;
								height: 100%;
							}
							.img{
								position: absolute;
								top:0;
								right: 0;
								z-index: 1;
								opacity: 0.12;
							}
							span{
								font-size: 32px;
								font-weight: 700;
								color: #464646;
								z-index: 2;
								position: relative;
							}
						}
					}
				}
			}
		}
	}
	.benefits{
		padding: 120px 0 110px;
		.row{
			margin: 0 -20px;
			.col{
				padding: 0 20px;
			}
		}
		.copy{
			position: relative;
			&:before{
				content: '';
				width: 146px;
				height: 146px;
				opacity: .2;
				background-image: url('/images/academy/ico-gun.svg');
				background-repeat: no-repeat;
				background-size: 146px;
				background-position: center;
				position: absolute;
				left: -73px;
				top: -50px;
				z-index: 0;
			}
			.title,
			.desc{position: relative;}
			.title{
				color: #0088ff;
			}
			p{
				margin: 15px 0;
				font-size: 16px;
				line-height: 26px;
			}
		}
		.benefit-content-box{
			text-align: left;
			background-color: #fff;
			height: 100%;
			position: relative;
			transform: skewY(-3deg);
			box-shadow: 0 4px 8px 0 rgba(0,0,0,0.15);
			transition: box-shadow .3s ease;
			max-width: 445px;
			margin: 0 0 0 auto;
			&:before{
				content: '';
				width: 131px;
				height: 131px;
				background-repeat: no-repeat;
				background-position: center;
				position: absolute;
				background-image: url('/images/academy/ring.svg');
				bottom: -67px;
				left: -67px;
				transform: skewY(3deg);
				z-index: 1;
			}
			.benefit-content-box-header{
				padding: 45px 20px 0;
				position: relative;
				z-index: 2;
				background-color: rgba(#0088ff, 1);
				transition: background .2s ease;
				color: #fff;
				&:before{
					content: '';
					width: 100%;
					height: 30%;
					position: absolute;
					bottom: -12px;left:0;
					background-color: rgba(#0088ff, 1);
					transform: skewY(3deg);
					transition: background .2s ease;
				}
				.title{
					line-height: 34px;
				}
			}
			.benefit-content-box-body{
				padding: 25px 20px 18px;
				background-color: #fff;
				position: relative;
				z-index: 1;
			}
			.inner-container{
				transform: skewY(3deg);
			}
		}

		#ml-content-sec{
			.copy{
				&:before{
					background-image: url('/images/academy/ico-arrow.svg');
				}
			}
		}
	}
	.class{
		width: 100%;
		background-color: #fcba2a;
		position: relative;
		padding: 72px 0;
		.row{
			margin: 0 -20px;
			.col{
				padding: 0 20px;
			}
		}
		.img{
			img{margin: auto;}
		}
		.desc{
			p{font-size: 16px;}
		}
	}
	.register{
		padding: 92px 0;
		.title{
			margin-bottom: 26px;
		}
		.btn-wrapper{
			margin-bottom: 33px;
			.btn-sign{
				&:before{
					content: '';
					width: 97%;
					height: 57%;
					position: absolute;
					top: 0;
					right: 0;
					bottom: 0;
					left: 0;
					margin: auto;
					z-index: 0;
					box-shadow: 0 9px 12px 0 rgba(255, 103, 0, 0.75);
					transition: box-shadow .3s ease;
				}
				svg{
					position: relative;
					z-index: 1;
				}
				.button-signup-bg,
				.button-signup-text,
				.button-signup-border{
					transition: .3s ease;
				}
				.button-signup-bg{
					fill: #ff6700;
					stroke: #ff6700;
				}
				.button-signup-text{
					fill: #fff;
				}
				&:hover{
					&:before{
						box-shadow: 0 12px 24px 0 rgba(255, 103, 0, 0.55);
					}
				}
			}
		}
		.link{
			color: #ff6700;
			text-decoration: underline;
			&:hover{
				text-decoration: none;
			}
		}
	}
	.contact{
		background-color: rgba(226, 68, 0, 0.05);
		padding: 32px 0;
		h5{
			line-height: 22px;
		}
		h5.medium{
			text-decoration: underline;
		}
		.btn-contact{
			position: relative;
			padding-left: 28px;
			.ico-wa{
				position: absolute;
				left: 0;
				top: 1px;
			}	
		}
	}
}

#ml-content{
	.package-list{
		.package-list-item{
			&.red{
				&:before{
					background-image: url('/images/academy/ico-book.svg');
				}
			}
			&.blue{
				&:before{
					background-image: url('/images/academy/ico-arrow.svg');
				}
			}
		}
	}
}

.bg-ornament{
	position: relative;
	background-color: #fff;
	&:before{
		content: '';
		width: 100%;
		height: 100%;
		position: absolute;
		top:0;left:0;
		background-image: url('/images/academy/ornament.svg');
		z-index: 0;
		background-position: -15px center;
	}
	.content{
		position: relative;
		z-index: 1;
	}
}

.bg-ornament2{
	position: relative;
	background-color: #fff;
	&:before{
		content: '';
		width: 100%;
		height: 100%;
		position: absolute;
		top:0;left:0;
		background-image: url('/images/academy/ornament2.svg');
		z-index: 0;
		background-position: top;
	}
	.content{
		position: relative;
		z-index: 1;
	}
}

.bg-ornament3{
	position: relative;
	background-color: #fff;
	&:before{
		content: '';
		width: 100%;
		height: 100%;
		position: absolute;
		top:0;left:0;
		background-image: url('/images/academy/ornament3.svg');
		z-index: 0;
		background-position: center;
	}
	.content{
		position: relative;
		z-index: 1;
	}
}

@media screen and (max-width: 768px){
	.page--academy{
		.content{
			padding: 0 15px;
		}
		.package{
			.video-wrapper{
				.link-video{
					padding-bottom: 47%;
				}
			}
			.content{
				.package-list{
					.row{
						.col{
							padding-bottom: 52px;
						}
					}
				}
			}
		}
	}
}

@media screen and (max-width: 520px){
	.page--academy{
		.mainbanner{
			height: 140px;
		}
		h1.title{
			font-size: 28px;
			letter-spacing: -0.5px;
			line-height: 34px;
		}
		.introduction{
			padding-bottom: 150px;
			.content{
				.img{
					margin-bottom: 15px;
					img{max-width: 160px;}
				}
				.title{
					margin-bottom: 18px;
				}
			}
		}
		.package{
			// padding-top: 200px;
			.video-wrapper{
				padding: 0 15px;
				.link-video{
					padding-bottom: 56%;
				}
			}
			.content{
				.title{
					margin-bottom: 14px;
				}
				.desc{
					margin-bottom: 32px;
				}
				.info-launch{
					width: 100%;
					height: 77px;
					background-size: 380px;
					h3{font-size: 16px;}
					h2{font-size: 24px;}
					.info-launch-inner{
						&:before,
						&:after{
							border-top: 39px solid transparent;
							border-bottom: 39px solid transparent;
						}
					}
				}
				.package-list{
					margin-top: 60px;
					.package-list-item{
						box-shadow: 0 4px 8px 0 rgba(0,0,0,0.15) !important;
						&:hover{
							&.red{
								box-shadow: 0 0px 0px 0 rgba(255,45,0,0.55);
								.package-list-item-header{
									background-color: rgba(255, 234, 229, 0);
									&:before{
										background-color: rgba(255, 234, 229, 0);
									}
								}
								.btn-wrapper{
									.btn-sign{
										&:before{
											box-shadow: 0 10px 12px 0 rgba(255, 45, 0, 0.75);
										}
									}
									.button-signup-bg{
										fill: #FF2D00;
									}
									.button-signup-text{
										fill: #fff;
									}
								}
							}
							&.blue{
								box-shadow: 0 20px 32px 0 rgba(0,136,255,0);
								.package-list-item-header{
									background-color: rgba(229,243,255,0);
									&:before{
										background-color: rgba(229,243,255,0);
									}
								}
							}
						}
					}
				}
				.btn-wrapper{
					.btn-sign{
						&:before{
							box-shadow: 0 6px 12px 0 rgba(255, 45, 0, 0.55);
						}
						.button-signup-bg{
							fill: #FF2D00;
						}
						.button-signup-text{
							fill: #fff;
						}
					}
				}
				.tab-game-class{
					.tab-button{
						ul{
							li{
								min-width: 160px;
								min-height: 41px;
								span{
									font-size: 16px;
								}
								.img{
									height: 41px;
									img{
										max-height: 100%;
									}
								}
							}
						}
					}
				}
			}
		}
		.benefits{
			padding: 42px 0;
			.content{
				text-align: center;
			}
			.copy{
				text-align: center;
				padding-bottom: 42px;
				&:before{
					width: 124px;
					height: 124px;
					background-size: 124px;
					top:-22px;
				}
			}
		}
		.class{
			padding: 42px 0;
			.copy-left{
				flex-direction: column-reverse;
			}
			.copy-left,
			.copy-right{
				text-align: center;
				padding: 26px 0;
			}
			.img{
				padding-bottom: 20px;
				img{max-width: 178px;}
			}
			.title{
				margin-bottom: 12px;
			}
			.col{
				text-align: center;
			}
		}
		.register{
			padding: 52px 0;
		}
		.contact{
			h5{
				span{
					display: block;
				}
			}
		}
	}
}