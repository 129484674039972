.mainbanner {
	width: 100%;
	height: 400px;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
}

.mainbanner_h140 {
	height: 140px;
}

.mainbanner_h290 {
	height: 290px;
}

.mainbanner_h300 {
	height: 300px;
}

.socmed{
	li{
		a{
			display: inline-block;
			opacity: .5;
			padding: 1px;
			&:hover{
				opacity: 1;
			}
		}
	}
}

.card{
	background-color: #fff;
	border: 1px solid #e0e0e0;
	border-radius: 2px;
	&.no-border{border: 0;}
	&.normal-rect{border-radius:0;}
}

.achievement{
	.achievement--img{
		width: 100%;
		height: 100%;
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;
		flex-direction: column;
		h2{color: #fff;}
		img{margin-bottom: 10px;}
	}
	.achievement--list{
		padding: 20px 30px;
		&.show-more{
			table{
				border-bottom: 1px solid #CBCBCB;
			}
			.btn--primary{
				display: inline-block;
			}
		}
		table{
			tr{
				td{
					padding-bottom: 20px;
				}
			}
		}
		.padding-x10{
			padding-left:10px;
			padding-right:10px;
		}
		.padding-r10{
			padding-right:10px;
		}
		h6{margin-bottom: 6px;}
	}
	.btn--primary{margin-top: 15px;}
	.btn--seemore{
		margin: 15px 0;
		display: inline-block;
		color: rgba(56,56,56,.5);
		&:hover{
			color: rgba(56,56,56,1);
		}
	}
	.ico-trophy {
		max-width: 50px;
	}
	.ico-game {
		max-width: 100px;
	}
}


.teams-info{
	.btn--see-all{
		@extend .barlow.semibold;
		font-size: 18px;
		color: #ff8c00;
		letter-spacing: 0.38px;
		text-transform: uppercase;
		transition: .3s ease;
		position: relative;
		&:hover{
			color: darken(#ff8c00, 5%);
		}
	}
	.row{
		.col{
			padding: 20px;
		}
	}
	.lr{border-right: 1px solid #e0e0e0;}
	// .achievement-list{
	// 	.btn--see-all{
	// 		padding-right: 10px;
	// 		&:after{
	// 			content: '';
	// 			width: 7px;
	// 			height: 7px;
	// 			border-top: 2px solid #ff8c00;
	// 			border-left: 2px solid #ff8c00;
	// 			position: absolute;
	// 			transform: rotate(135deg);
	// 			top:8px;bottom:0;right:0;
	// 		}
	// 		&:hover{
	// 			color: darken(#ff8c00, 5%);
	// 			&:after{
	// 				border-top: 2px solid darken(#ff8c00, 5%);
	// 				border-left: 2px solid darken(#ff8c00, 5%);
	// 			}
	// 		}
	// 	}
	// }
}
ul.match-ticker--tab{
	margin: 0;
	li{
		@extend .rubick;
		font-weight: 500;
		font-size: 24px;
		color: #363636;
		letter-spacing: 0.5px;
		opacity: .3;
		margin-right: 24px;
		cursor: pointer;
		position: relative;
		&:last-child{margin-right: 0;}
		&:before{
			content:'';
			width: 0;
			height: 3px;
			background-color: #f7b81c;
			position: absolute;
			bottom: -6px;
			left: 0;
			transition: .3s ease;
		}
		&.active{
			opacity: 1;
			&:before{
				width: 30px;
			}
		}
	}
	&.big{
		li{
			font-size: 36px;
			letter-spacing: 0.5px;
			line-height: 43px;
			&:before{
				height: 5px;
				bottom: -10px;
			}
			&.active{
				&:before{
					width: 50px;
				}
			}
		}
	}
}
.match-detail{
	.tab-content{
		display: none;
	}
	.match-detail--header{
		display: flex;
		align-items: center;
		justify-content: space-between;
		.title{
			@extend .rubick;
			font-size: 24px;
			color: #363636;
			letter-spacing: 0.5px;
			font-weight: 500;
		}
	}
	.match--table{
		margin: 21px 0 0;
		.table--list{
			border-bottom: 1px solid #cbcbcb;
			display: flex;
			height: 71px;
			&:first-child{
				border-top: 1px solid #cbcbcb;
			}
			a{
				display: flex;
				width: 100%;
				transition: .3s ease;
				&:hover{
					.table--list-right{
						&:before{
							opacity: 1;
						}
						&:after{
							opacity: 1;
						}
					}
				}
			}
			.cell{
				display: flex;
				align-items: center;
				justify-content: center;
				height: 100%;
			}
			.table--list-left{
				background-color: #e5e5e5;
				width: 36px;
				border-right: 2px solid #f7b81c;
			}
			.table--list-right{
				width: calc(100% - 36px);
				background: #f8f8f8;
				color: #363636;
				overflow: hidden;
				position: relative;
				@extend .barlow;
				transition: .3s ease;
				&:before{
					content:'';
					width: 100%;
					height: 100%;
					background: linear-gradient(90deg, rgba(255,255,255,0.50) 0%, rgba(247,184,28,0.50) 100%);
					opacity: 0;
					transition: .3s ease;
					position: absolute;
					z-index: 0;
				}
				&:after{
					content:'';
					width: 120px;
					height: 69px;
					background-image: url(/images/ico/ico-dash.svg);
					background-size: 120px;
					background-repeat: no-repeat;
					position: absolute;
					right: -10px;top:0;
					z-index: 1;
					opacity: 0;
					transition: .3s ease;
				}
				.row{
					padding: 5px;
					.col{
						padding: 5px;
					}
					&.flex{
						position: relative;
						z-index: 2;
					}
				}
				h3{
					font-size: 18px;
					letter-spacing: 0.22px;
					display: block;
					width: 100%;
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
				}
				.time{
					@extend .rubick;
					font-size: 14px;
					opacity: .5;
					text-align: right;
					line-height: 21px;
					display: block;
					width: 100%;
				}
				.live{
					display: inline-block;
					padding: 4px 8px;
					text-transform: uppercase;
					background-image: linear-gradient(225deg, #ff512f 0%, #ff1414 100%);
					background-color: rgba(255,255,255,.15);
					box-shadow: 0 0 2px 0 rgba(255,81,47,0.75);
					border-radius: 2px;

					@extend .rubick;
					font-size: 12px;
					color: #fff;
					letter-spacing: 0;
				}
				.match{
					display: flex;
					margin-top: 8px;
					.team{
						&:last-child{
							width: calc(100% - 51px);
							.team-name{
								width: calc(100% - 20px);
								display: inline-block;
								text-overflow: ellipsis;
								overflow: hidden;
								white-space: nowrap;
							}
						}
					}
				}
				.match-result{
					margin-top: 8px;
					font-size: 18px;
					.score{margin-left: 12px;}
				}
				.team{
					display: flex;
					align-items: center;
				}
				.team-vs{margin: 0 8px;}
				.team-name{
					font-weight: 600;
					letter-spacing: 0.38px;
					margin-left: 6px;
				}
				// .team-logo{margin-right: 6px;}
				.tournament-ranking{
					// padding: 0 5px;
					width: 60px;
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: center;
					img{
						width: 42px;
					}
				}
			}
		}
		.table--list--placeholder{
			text-align: center;
			width: 100%;
			@extend .barlow;
			height: 355px;
			display: flex;
			align-items: center;
			justify-content: center;
			background-color: #f8f8f8;
			p{
				color: #939393;
				font-size: 14px;
				letter-spacing: 0.15px;
			}
		}
	}
}
.match-sorting{
	span{
		@extend .barlow.medium;
		font-size: 14px;
		color: #757575;
		letter-spacing: 0;
		margin-right: 8px;
	}
	select{
		@extend .barlow.medium;
		font-size: 14px;
		color: #ff8c00;
		letter-spacing: 0;
		border: 1px solid #757575;
		border-radius: 2px;
		padding: 8px 25px 8px 8px;
		height: 36px;
		-webkit-appearance: none;
		-moz-appearance:    none;
		appearance:         none;
		background-image: url(/images/ico/ico-chevrondown-mini.svg);
		background-repeat: no-repeat;
		background-position: 94%;
	}
}
.table-filter{
	display: flex;
	flex-wrap: wrap;
	margin: 30px 0 0;
	.filter--list{
		background-color: #e5e5e5;
		border-radius: 2px;
		@extend .barlow.medium;
		font-size: 10px;
		color: #363636;
		letter-spacing: 0.21px;
		padding: 8px;
		margin-right: 10px;
		cursor: pointer;
		transition: .3s ease;
		text-transform: uppercase;
		&:hover{
			background-color: darken(#e5e5e5, 5%);
		}
		&.active{
			color: #fff;
			background-color: #ff8c00;
			&:hover{
				background-color: darken(#ff8c00, 5%);
			}
		}
	}
}
.table-pagination{
	display: flex;
	.pagination--list{
		@extend .barlow.semibold;
		font-size: 16px;
		color: #757575;
		letter-spacing: 0;
		text-align: center;
		padding: 4px 8px;
		transition: .3s ease;
		&:hover{
			color: darken(#757575, 5%);
		}
		&.active{
			color: #ff8c00;
			pointer-events: none;
			&:hover{
				color: darken(#ff8c00, 5%);
			}
		}
		&.next,
		&.prev,
		&.first,
		&.last{
			display: flex;
			align-items: center;
			justify-content: center;
			a{
				font-size: 0;
				position: relative;
				width: 12px;
				height: 12px;
				display: flex;
				align-items: center;
				justify-content: center;
				&:before{
					content: '';
					width: 8px;
					height: 8px;
					position: absolute;
					top:0;
					bottom:0;
					margin: auto;
				}
			}
		}
		&.next{
			a{
				&:before{
					transform: rotate(45deg);
					border-top: 2px solid #757575;
					border-right: 2px solid #757575;
					right:4px;
				}
			}
		}
		&.prev{
			a{
				&:before{
					transform: rotate(-45deg);
					border-top: 2px solid #757575;
					border-left: 2px solid #757575;
					left:4px;
				}
			}
		}
		&.last{
			a{
				&:before{
					transform: rotate(45deg);
					border-top: 2px solid #757575;
					border-right: 2px solid #757575;
					right: 10px;
				}

				&:after{
					transform: rotate(45deg);
					border-top: 2px solid #757575;
					border-right: 2px solid #757575;
					right:4px;

					content: '';
					width: 8px;
					height: 8px;
					position: absolute;
					top:0;
					bottom:0;
					margin: auto;
				}
			}
		}
		&.first{
			a{
				&:before{
					transform: rotate(-45deg);
					border-top: 2px solid #757575;
					border-left: 2px solid #757575;
					left:10px;
				}

				&:after{
					transform: rotate(-45deg);
					border-top: 2px solid #757575;
					border-left: 2px solid #757575;
					left:4px;

					content: '';
					width: 8px;
					height: 8px;
					position: absolute;
					top:0;
					bottom:0;
					margin: auto;
				}
			}
		}
	}
}
.ads-mobile__container{
	padding-bottom: 20px;
	padding-right: 10px;
	padding-left: 10px;

	display: none;
	a,
	img{width: 100%;}
}

.tab--container{
	.tab-content{
		display: none;
	}
}

.select__dropdown-menu-mobile{
	@extend .barlow;
	font-weight: 500;
	font-size: 24px;
	letter-spacing: 0;
	background-color: #f8f8f8;
	height: 53px;
	line-height: 53px;
	padding: 0 30px 0 10px;
	position: relative;
	text-transform: uppercase;

	display: none;

	&.active{
		&:after{
			transform: rotate(180deg);
		}
	}

	&:after{
		content: '';
		background-image: url(/images/ico/ico-chevrondown-orange.svg);
		width: 12px;
		height: 9px;
		position: absolute;
		top:0;bottom:0;right: 15px;
		margin: auto;
		transition: .3s ease;
		transform: rotate(0deg);
	}
	
	.flag-icon{
		width: 26px;
	    height: 26px;
	    background-size: cover;
	    border-radius: 100%;
	    box-shadow: inset 0px 0px 8px 0 rgba(0, 0, 0, 0.25);	
	    margin-right: 6px;
	}
}

.dropdown-menu-mobile--list{
	@extend .barlow;
	font-weight: 500;
	font-size: 24px;
	letter-spacing: 0;
	background-color: #fff;
	height: 53px;
	line-height: 53px;
	padding: 0 30px 0 10px;
	position: relative;
	text-transform: uppercase;
	border-bottom: 1px solid #cbcbcb;
	&:first-child{
		border-top: 1px solid #cbcbcb;
	}
	&:active{
		background-color: #f8f8f8;
	}
	.flag-icon{
		width: 26px;
	    height: 26px;
	    background-size: cover;
	    border-radius: 100%;
	    box-shadow: inset 0px 0px 8px 0 rgba(0, 0, 0, 0.25);	
	    margin-right: 6px;
	}
}

@media(max-width: 768px) {
	.xs-mainbanner_h595 {
		height: 595px;
	}

	.select__dropdown-menu-mobile{
		display: block;
		margin: 28px 0 30px;
	}

	.achievement{
		.col.m5{display: none;}
		.col.m7{width:100%;}
		.achievement--list__mobile__title{margin-bottom: 6px;display:block !important;}
		.achievement--list__mobile{
			.list__mobile__li{
				padding: 30px 0;
				border-bottom: 1px solid #E0E0E0;
				h6{font-size: 18px;margin-bottom: 10px;}
				small{font-size: 16px;}
				&:last-child{
					border-bottom: 0;
				}
				.achievement--logo{
					margin-top: 15px;
					.ico-trophy{max-width: 50px;}
					img{
						max-width: 100px;
						margin: 0 10px;
						&:first-child{
							margin-left: 0;
						}
						&:last-child{
							margin-right: 0;
						}
					}
				}
			}
		}
	}

	.teams-info{
		.lr{
			border-right: 0 solid #e0e0e0;
			border-bottom: 1px solid #e0e0e0;
		}
		.btn--see-all{
			font-size: 18px;
			text-transform: uppercase;
			letter-spacing: 0.38px;
		}
		.btn--see-all-container{
			text-align: center;
			margin: 15px 0 0;
		}
		.achievement-list{
			.btn--see-all{
				&:after{display: none;}
			}
		}
	}
	.match-detail{
		ul.match-ticker--tab{
			width: 100%;
			position: relative;
			&:before{
				content:'';
				width: 100%;
				height: 1px;
				background-color: #cbcbcb;
				position: absolute;
				bottom: -8px;
				left:0;
			}
			li{
				margin-right: 0;
				width: 50%;
				text-align: center;
				display: block;
				&:before{
					width: 100%;
					transform: scaleX(0);
					bottom: -8px;
				}
				&.active{
					&:before{
						width: 100%;
						transform: scaleX(1);
					}
				}
			}
			&.big{
				li{
					font-size: 24px;
					letter-spacing: 0.4px;
					line-height: 28px;

					min-height: 56px;
					display: flex;
					align-items: center;
					justify-content: center;
				}
			}
		}
		.match--table{
			.table--list{
				height: auto;
				.table--list-right{
					// .col{
					// 	&:nth-child(2){
					// 		text-align: right;
					// 	}
					// }
					h3{
						font-size: 14px;
						display: block;
						&+ .time,
						&+ .live{
							margin-top: 8px;
							display: inline-block !important;
						}
					}
					.match-result{
						font-size: 14px;
						.score{margin-left: 7px;}
					}
				}
			}
		}

		#upcoming-content,
		#result-content{
			.table--list-right{
				.row{
					.col.m3.xs4{
						text-align: right;
					}
				}
			}
		}
	}
	.match-sorting{
		display: none;
	}
	.table-pagination{
		justify-content: center;
	}
	.table-filter{
		flex-wrap: nowrap;
		overflow: auto;
		.filter--list{
			white-space: nowrap;
			margin-right: 8px;
		}
	}
	.ads-mobile__container{
		display: block;
	}
}

@media screen and (max-width: 480px){
	.achievement{
		.achievement--list__mobile{
			.list__mobile__li{
				padding: 20px 0;
				h6{font-size: 14px;margin-bottom: 1px;}
				small{font-size: 12px;}
			}
		}
	}
}
