.comment{
	.morelink{
		display: inline-block;
		text-decoration: none;
		font-size: 14px;
		color: #FF8C00;
		letter-spacing: 0.25px;
		transition: .3s ease;
		@extend .barlow;
		font-weight: 600;
		padding-right: 10px;
		&:after{
			content: '';
			width: 5px;
			height: 5px;
			border-top: 1px solid #FF8C00;
			border-right: 1px solid #FF8C00;
			-webkit-transform: rotate(45deg);
			transform: rotate(45deg);
			position: absolute;
			right: 3px;
			top: 9px;
			transition: .3s ease;
		}
		&.less{
			// display: block;
			max-width: 85px;
			padding-right: 13px;
			display: block;
			&:after{
				transform: rotate(-45deg);
				top: 11px;
				right: 4px;
			}
		}
	}
}