::-webkit-input-placeholder { opacity:0.5; }
::-moz-placeholder { opacity:0.5; } /* firefox 19+ */
:-ms-input-placeholder { opacity:0.5; } /* ie */
input:-moz-placeholder { opacity:0.5; }

.btn {

}

.btn {
  height: 45px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 8px 14px;
  box-shadow: 0 2px 3px 0 rgba(#383838, .25);
  background-color: #FCDD3C;
  border-radius: 2px;
  border: none;
  &:hover {
    background-color: darken(#FCDD3C, 6.5%);
  }
}

.btn_w170 {
  min-width: 170px;
}

.btn_w200 {
  min-width: 200px;
}

.btn-share {
  height: 30px;
  box-shadow: none;
  background-color: #E5E5E5;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0;
  color: #696969;
  transition: background-color .3s ease;
  &:hover {
    background-color: darken(#E5E5E5, 10%);
  }
}

form,
.form{
    width: 100%;
    margin: 0;
    padding: 0;
    input, textarea, select, button, a{
        &:focus,&:active{ outline: 1px solid #f9f9f9; }
    }
    input, textarea, select {
        position: relative;
        font-family: 'Open Sans', sans-serif;  
        font-size:14px;
        color: #3b3b3b;         
        border: 1px solid #e0e0e0;
        border-radius:2px;
        padding: 5px 10px;
        -webkit-appearance: none;
        vertical-align: middle;
        width:auto;
        height:40px;        
        &:focus,
        &:active
        { border:1px solid #86B2E5; background:#f9f9f9; }
        &.small{
            font-size:11px;
            padding:5px 10px;
            height:35px;
        }
        &.qty{
            width:42px;
            font-weight:bold;
            text-align:center;
        }
    }
    textarea{ 
        height:auto;
        resize: none;
        &.small{ height:auto; padding:10px; }
    }
    input[type="button"]{
        &.qtyminus{
            cursor:pointer;
            font-size: 18px;
            width:35px;
            color:#F26877;
            font-weight:bold;
            padding: 0px 10px;
            border-right: 0;
            border-left: 0;
            border-radius:50% 0 0 50%;
            background: linear-gradient(to bottom, #fff 0%, #e5e5e5 100%);
        }
        &.qtyplus{
            @extend .qtyminus;
            border-radius:0 50% 50% 0;            
        }
    }
    input[type="checkbox"], 
    input[type="radio"]{
        &~ label{ display:inline-block; } 
    }
    input[type="file"]{
        background:white;
        border:0;
        padding:2px 0;
    }
    input[type=number]::-webkit-outer-spin-button,
    input[type=number]::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    input[type=number] {
        -moz-appearance:textfield;
    }    
    label{ 
        display:block; 
        margin-bottom: 5px; 
    }
    .select{
        position:relative;
        background-image: url(/images/ico/ico-arrowselect.svg);
        background-repeat: no-repeat;
        background-position: right center;
        appearance: none;
        text-indent: 0.01px;
        text-overflow: '';
    }
    .select{
        position: relative;
        &:before{
            content:'';
            position: absolute;
            top:0; bottom:0; right:10px;
            margin: auto;
            background:url(/images/ico/ico-arrowselect.svg);
            width:9px; height:4px;
            z-index:10;
        }            
    }    
    .field{
        position: relative;
        width:320px;
        max-width:100%;
        margin-bottom:15px; 
        input, 
        select, 
        textarea{
           width:100%;
           // margin:2px 0;
           &.qty{ width:42px; }               
        }
        input[type="text"]{
            &~ i{ 
                position: absolute;
                top:0; bottom:0; right:10px;
                margin:auto;
                width:14px; height:14px;
                background-size:100%;
            }
            &.small{
                &~ i{ 
                    position: absolute;
                    top:0; bottom:0; right:15px;
                    margin:auto;
                    width:12px; height:12px;
                    background-size:100%;
                }
            }
        }
        input[type="file"]{ height:auto; }
        input[type="checkbox"], 
        input[type="radio"]{
            margin-bottom:0;
            width: auto; 
        }
        input[type="submit"]{
            width:auto;
            height:auto;
            margin:auto;
        }
        label{ 
            margin-right:5px; margin-bottom: 5px; 
            &.wells{ display:table; padding: 8px 10px; }
        }
        // span{ color:white; }        
        &.error{
            .info--error{ display: inline-block; }
            input, 
            select, 
            textarea{
               background:rgba(165,17,9,0.2);
               border:1px solid #a9363e;               
            }
        }
        .info--error{
            display: none;
            float: right;
            color: #ff6060 !important;
            font-size:12px;
        }
        .ico__wrapper{
            position: relative;
            width:100%;
            .right{
                position: absolute;
                top:0; bottom:0; right:8px;
                margin:auto;
            }
            .left{
                @extend .right;
                right:initial; left:8px;
            }   
        }
    }
    .option__expand{
        display:none;
    }
    .input--2{
        position:relative;
        &:before{
            content: attr(placeholder);
            color:#bebebe;
            cursor:text;
            position: absolute;
            top: 0; bottom: 0; left: 0;
            margin: auto;
            padding:16px 10px;
            z-index:1;
            transition:0.1s ease;
        }
        &.active{
            &:before{
                font-size:10px;
                top:-8px; left:0; bottom:initial;
            }
            input{ 
                padding: 20px 10px 10px 10px;
            }
        }
        input{ 
            height:50px;
            padding: 12px 10px;
        }
    }
    .input--date{
        position:relative;
        border-radius: 2px;
        background:transparent !important;
        input:active, input:focus{ border:1px solid #e0e0e0; } 
        .ico-date{
            background: url(/images/ico/ico-dategray.svg) no-repeat center;
            position: absolute;
            top: 0; bottom: 0; right: 0 !important;
            margin: auto;
            width: 40px !important; height: 100% !important;
            background-size: 13px !important;
            cursor: pointer;
        }        
        // &:after{
        //     content: "";
        //     position: absolute;
        //     top: 0; bottom: 0; right: 0;
        //     margin: auto;
        //     width: 40px; height: 100%;
        //     border-left: 1px solid #e9e9e9;            
        // }
        &.active{
            // border-radius: 2px;
            // border: 1px solid #777;
            // &:after{ border-left: 1px solid #777; }
            // input{ 
            //     border:0;
            //     padding: 4px 9px;
            //     height: 33px;
            //     &:active, &:focus{ border:0; } 
            // }
        }
        // &.green:after{ background: url(/images/ico/ico-dategreen.svg) no-repeat center; }            
        // &.red:after{ background: url(/images/ico/ico-datered.svg) no-repeat center; }
    }
    .input--error{
        position:relative;
        &:after{
            content:"";
            position:absolute;
            top:0; bottom:0; right:10px;
            margin:auto;
            width:14px; height:14px;
            border:0;
            background:url(/images/ico/ico-error.svg);
        }
        input, textarea, select, button, a{
            &:focus,&:active{ outline:0; }
        }
        input,
        select, 
        textarea{
           border:1px solid #F26877;
           // color:#F26877 !important;
        }
        input[type="checkbox"], 
        input[type="radio"],
        input[type="file"]{
            border:0;
        }
        .selectize{
            .selectize-input{ 
                border:1px solid #F26877 !important;
            }
        }
        .small.selectize{
            .selectize-input{ 
                border:1px solid #F26877 !important; 
            }
        }
    }
    .msg--error{
        display: inline-block;
        border-bottom: 1px solid #e74001;
    }

    @include break (1024){
        .field{
            flex-flow:row wrap;
            input[type="checkbox"], 
            input[type="radio"]{
                width: 20px; height:20px;
                margin:2px 0;
            }
        }
    }
    @include break (600){
        input, textarea, select {
            width:100%;
        }
        input[type="checkbox"], 
        input[type="radio"]{ 
            width: 20px; height:20px;
        }
        .field{
            input, 
            select, 
            textarea{
                width:100%;              
            }
            .span6{
                width:100%;
                margin:0 0 15px 0;
                &:last-child{ margin:0; }
            }
        }
        .input--error{
            input,
            select, 
            textarea{
               border:1px solid #F26877;               
            }
            .selectize{
                .selectize-input{ border:1px solid #F26877 !important; }
            }
        }
    }
}

.form-control {
  width: 100%;
  background-color: #f0f0f0;
  border: 1px solid rgba(#383838, .25);
  border-radius: 2px;
  height: 45px;
  font-size: 18px;
  letter-spacing: 0;
  line-height: 22px;
  padding: 12px 20px 11px 20px;
  &:focus {
    border-color: #F7B81C;
    background-color: #f0f0f0;
  }
}

textarea.form-control {
  height: 200px;
}

@media(max-width: 768px) {
  .form-control {
    height: 35px;
    font-size: 14px;
    padding: 12px 15px 11px 15px;
  }
  select.form-control {
    padding: 0 15px;
    -moz-appearance: none; 
    -webkit-appearance: none; 
    appearance: none;
    background: url(/images/contact/arrow-select.png) no-repeat 98% 50%;
    background-color: #f0f0f0;
    border: 1px solid rgba(56, 56, 56, 0.25);
  }
textarea.form-control {
  height: 150px;
}
}