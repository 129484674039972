/* ############ Font Awesome ############ */
@font-face {
  font-family: 'FontAwesome';
  src: url('/fonts/fontawesome/fontawesome-webfont.eot?v=4.5.0');
  src: url('/fonts/fontawesome/fontawesome-webfont.eot?#iefix&v=4.5.0') format('embedded-opentype'), 
       url('/fonts/fontawesome/fontawesome-webfont.woff2?v=4.5.0') format('woff2'), 
       url('/fonts/fontawesome/fontawesome-webfont.woff?v=4.5.0') format('woff'), 
       url('/fonts/fontawesome/fontawesome-webfont.ttf?v=4.5.0') format('truetype'), 
       url('/fonts/fontawesome/fontawesome-webfont.svg?v=4.5.0#fontawesomeregular') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Material Design Icon */
@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/material-design-icons/MaterialIcons-Regular.eot'); /* For IE6-8 */
  src: local('Material Icons'),
       local('MaterialIcons-Regular'),
       url('/fonts/material-design-icons/MaterialIcons-Regular.woff2') format('woff2'),
       url('/fonts/material-design-icons/MaterialIcons-Regular.woff') format('woff'),
       url('/fonts/material-design-icons/MaterialIcons-Regular.ttf') format('truetype');
}
.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 16px;  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
}


/* ############ Barlow ############ */
@import url('https://fonts.googleapis.com/css?family=Barlow:300,400,400i,500,600,600i,700');
// font-family: 'Rubik', sans-serif;

/* ############ Rubick ############ */
@import url('https://fonts.googleapis.com/css?family=Rubik:300,400,500,700');
// font-family: 'Barlow Semi Condensed', sans-serif;

/* ############ Open Sans ############ */
@font-face {
    font-family: 'opensans-italic';
    src: url('/fonts/opensans/opensans-italic.eot');
    src: url('/fonts/opensans/opensans-italic.eot?#iefix') format('embedded-opentype'),
         url('/fonts/opensans/opensans-italic.woff') format('woff'),
         url('/fonts/opensans/opensans-italic.svg#opensans-italic') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'opensans-regular';
    src: url('/fonts/opensans/opensans-regular.eot');
    src: url('/fonts/opensans/opensans-regular.eot?#iefix') format('embedded-opentype'),
         url('/fonts/opensans/opensans-regular.woff') format('woff'),
         url('/fonts/opensans/opensans-regular.svg#opensans-regular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'opensans-semibold';
    src: url('/fonts/opensans/opensans-semibold.eot');
    src: url('/fonts/opensans/opensans-semibold.eot?#iefix') format('embedded-opentype'),
         url('/fonts/opensans/opensans-semibold.woff') format('woff'),
         url('/fonts/opensans/opensans-semibold.svg#opensans-semibold') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'opensans-bold';
    src: url('/fonts/opensans/opensans-bold.eot');
    src: url('/fonts/opensans/opensans-bold.eot?#iefix') format('embedded-opentype'),
         url('/fonts/opensans/opensans-bold.woff') format('woff'),
         url('/fonts/opensans/opensans-bold.svg#opensans-bold') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'opensans-extrabold';
    src: url('/fonts/opensans/opensans-extrabold.eot');
    src: url('/fonts/opensans/opensans-extrabold.eot?#iefix') format('embedded-opentype'),
         url('/fonts/opensans/opensans-extrabold.woff') format('woff'),
         url('/fonts/opensans/opensans-extrabold.svg#opensans-extrabold') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'opensans-semibold_italic';
    src: url('/fonts/opensans/opensans-semibolditalic.eot');
    src: url('/fonts/opensans/opensans-semibolditalic.eot?#iefix') format('embedded-opentype'),
         url('/fonts/opensans/opensans-semibolditalic.woff') format('woff'),
         url('/fonts/opensans/opensans-semibolditalic.svg#opensans-semibold_italic') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'opensans-bold_italic';
    src: url('/fonts/opensans/opensans-bolditalic.eot');
    src: url('/fonts/opensans/opensans-bolditalic.eot?#iefix') format('embedded-opentype'),
         url('/fonts/opensans/opensans-bolditalic.woff') format('woff'),
         url('/fonts/opensans/opensans-bolditalic.svg#opensansbold_italic') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'opensans-extrabold_italic';
    src: url('/fonts/opensans/opensans-extrabolditalic.eot');
    src: url('/fonts/opensans/opensans-extrabolditalic.eot?#iefix') format('embedded-opentype'),
         url('/fonts/opensans/opensans-extrabolditalic.woff') format('woff'),
         url('/fonts/opensans/opensans-extrabolditalic.svg#opensans-extrabold_italic') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'opensans-light';
    src: url('/fonts/opensans/opensans-light.eot');
    src: url('/fonts/opensans/opensans-light.eot?#iefix') format('embedded-opentype'),
         url('/fonts/opensans/opensans-light.woff') format('woff'),
         url('/fonts/opensans/opensans-light.svg#opensans-light') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'opensans-light_italic';
    src: url('/fonts/opensans/opensans-lightitalic.eot');
    src: url('/fonts/opensans/opensans-lightitalic.eot?#iefix') format('embedded-opentype'),
         url('/fonts/opensans/opensans-lightitalic.woff') format('woff'),
         url('/fonts/opensans/opensans-lightitalic.svg#opensans-light_italic') format('svg');
    font-weight: normal;
    font-style: normal;
}