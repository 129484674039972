.page--partner{
	padding: 50px 0;
	position: relative;
	.bg{
		width: 100%;
		height: 100%;

		position: fixed;
		top: 0;
		z-index: 0;
		img{
			position: absolute;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
	.title{
		@extend .barlow.semibold;
		font-size: 36px;
		letter-spacing: 0.5px;
		text-align: center;		
	}
	.partner--list{
		margin: 50px 0 0;
		.partner--list--item{
			display: flex;
			margin-bottom: 16px;
			padding: 20px 30px;
			background-color: #fff;
			box-shadow: 0 1px 3px 0 rgba(0,0,0,.15);
			border-radius: 10px;
			position: relative;
			&:last-child{
				margin-bottom: 0;
			}
			.item-left{
				width: 240px;
				height: 160px;
				background-color: #f8f8f8;
				border-radius: 10px;

				display: flex;
				align-items: center;
				justify-content: center;
			}
			.item-right{
				width: calc(100% - 240px);
				padding-left: 40px;
				text-align: justify;
				h3{
					@extend .barlow.semibold;
					font-size: 24px;
					letter-spacing: 0.5px;
					margin-bottom: 20px;
					padding-right: 100px;
				}
				p{
					@extend .opensans;
					font-size: 14px;
					letter-spacing: 0;
					line-height: 24px;
					margin-bottom: 0;
					// max-height: 96px;
					overflow: hidden;
					transition: .3s ease;
				}
				.partner-desc{
					position: relative;
					&.expand{
						p{
							max-height: 500px;
						}
					}
				}
			}
			.link{
				position: relative;
				padding-right: 10px;
				text-decoration: none;
				@extend .barlow;
				font-size: 14px;
				color: #ff8c00;
				letter-spacing: 0.25px;
				transition: .3s ease;
				display: inline-block;
				&:after{
					width: 5px;
					height: 5px;
					border-top: 1px solid #ff8c00;
					border-right: 1px solid #ff8c00;
					transform: rotate(45deg);
					position: absolute;
					right: 0;
					top: 5px;
					transition: .3s ease;
				}
				&.visit-website{
					font-size: 10px;
					letter-spacing: 0.18px;
					padding: 6px 22px 6px 12px;
					border: 1px solid #ff8c00;
					border-radius: 12px;
					background-color: #fff;

					position: absolute;
					top: 20px;
					right: 30px;
					&:after{
						right: 13px;
						top: 10px;
					}
					&:hover{
						background-color: #ff8c00;
						color: #fff; 
						&:after{
							border-color: #fff;
						}
					}
				}
				&.see-more{
					background-color: #fff;
					padding-left: 16px;
					position: absolute;
					right: 0;
					bottom: 4px;
					&:before{
						content: '...';
						position: absolute;
						left: 0;
						color: #464646;
					}
					&:after{
						top: 8px;
					}
				}
				&.show-less{
					display: none;
					&:after{
						transform: rotate(-45deg);
						top: 9px;
					}
				}
			}
		}
	}
}

@media screen and (max-width: 768px){
	.page--partner{
		padding: 35px 0;
		.bg{display: none;}
		.partner--list{
			margin: 35px 0 0;
			.partner--list--item{
				display: block;
				padding: 20px 0 10px;
				margin-bottom: 0;
				box-shadow: none;
				border-bottom: 1px solid rgba(#464646, .2);
				border-radius: 0;
				.item-left{
					width: 100%;
					height: auto;
					padding-bottom: 45%;
					position: relative;
					img{
						position: absolute;
						top:0;bottom:0;left:0;right:0;
						margin: auto;
					}
				}
				.item-right{
					width: 100%;
					padding: 10px;
					text-align: center;
					h3, p{margin-bottom: 10px;}
					h3{
						text-align: center;
						padding: 0;
					}
					.partner-desc{
						text-align: left;
					}
				}
				.link{
					&.visit-website{
						position: relative;
						top:0;right: 0;
					}
				}
			}
		}
	}
}

@media screen and (min-width: 769px){
	.page--partner{
		&.main__wrapper{
	        padding-top: 170px;
	    }
	}
}