.page--home{
	background-color: rgba(246,246,246,1);
	@mixin contentPadding {
		padding-top: 15px;
		padding-bottom: 15px;
	}
	.main--banner{
		background-image: linear-gradient(135deg, #FCDD3C 0%, #FF2E2E 100%);
		padding: 1px;
		.banner--box{
			color: #fff;
			border: 1px solid transparent;
			overflow: hidden;
			.inner{
				position: relative;
				&:after{
					content:'';
					width: 100%;
					height: 100%;
					position: absolute;
					top:0;left:0;
					background-image: linear-gradient(-180deg, rgba(0,0,0,0.00) 50%, rgba(0,0,0,0.70) 100%);
				}
			}
			article{
				position: absolute;
				bottom: 20px;left:20px;
				z-index: 1;

			}
			.img{
				transform: scale(1);
				transition: .5s ease;
				border-radius: 2px;
				background-repeat: no-repeat;
				background-size: cover;
				background-position: center;
			}
			a{
				display: block;height:100%;
				&:hover{
					.img{
						transform: scale(1.03);
					}
				}
			}
			h5{
				margin: 0 0 5px; 
				b{
					color: #FF8C00;
					text-transform: uppercase;
					font-weight: normal;
				}
				span{
					font-weight: 700;
				}
			}
			h3{
				margin:0;
			}
			.tag{
				padding: 6px 10px;
				position: absolute;
				right: 20px;top:10px;
				border-radius: 2px;
				color:#fff;
				h5{
					margin:0;
				}
				&.live{
					background-color: #FC0D1C;
				}
				&.playback{
					background-color: #B9B9BA;
				}
			}
		}

		#banner-news1{
			height: 456px;
		}
		#banner-news2{
			height: 230px;
		}
		#banner-news3{
			height: 230px;
		}
		#banner-news4{
			height: 341px;
		}
		#banner-news5{
			height: 345px;
		}
	}
	.division{
		height: 100vh;
		max-height: 800px;
		overflow: hidden;
		border-radius: 3px;
		background-image: linear-gradient(135deg, #FCDD3C 0%, #FF2E2E 100%);
		&.notvisible{
			.containerfull{opacity: 0;}
		}
		.containerfull{height: 100%;}
		.division--d{display: block;}
		.division--m{display: none;}

		.division__text{
			position: relative;
			height: 100px;
			z-index: 0;
			.text-1{
				position: absolute;
				top: 37px;
				left: 49px;
				z-index: 1;
			}
			.text-2{
				position: absolute;
				top: 19px;
				left: 217px;
			}

			.text-1-m{
				display: none;
				position: absolute;
				top: 20px;
				left: 15px;
				z-index: 1;
			}
			.text-2-m{
				display: none;
				position: absolute;
				top: 5px;
				left: 112px;
			}
		}
		.division__list{
			padding: 0 80px;
			white-space: nowrap;
			.slick-list{
				overflow: visible;
			}
			.slick-track{display: flex;}
			.slick-next,
			.slick-prev{
				width: 80px;
				height: 100%;
				&:before{
					content:'';
					width: 28px;
					height: 28px;
					border-top: 3px solid rgba(255,255,255,.5);
					border-left: 3px solid rgba(255,255,255,.5);
					position: absolute;
					top: 0;
					left: 0;
					right: 0;
					bottom: 0;
					margin: auto;
				}
				&.slick-disabled{
					opacity: 0;
					pointer-events: none;
				}
			}
			.slick-prev{
				left: 0px;
				z-index: 1;
				&:before{
					transform: rotate(-45deg);
				}
			}
			.slick-next{
				right: 0px;
				z-index: 1;
				&:before{
					transform: rotate(135deg);
				}
			}
			.division__list--item{
				width: 320px;
				height: 616px;
				display: inline-block;
				transform: skewX(-4deg);
				outline: 0;
				margin-left: -1px;
				.item--inner{
					width: 100%;
					height: 616px;
					border: 1px inset #000;
					transform: scale(1);
					overflow: hidden;				
					outline: 0;
					transform-origin: center;
					transition: .3s ease;
				}
				.item__wrapper{
					width: calc(100% + 44px);
					height: 616px;
					transform: skewX(4deg);
					position: relative;
					margin-left: -22px;
					&:before{
						content:'';
						width: 100%;
						height: 100%;
						background-color: rgba(0,0,0,.5);
						position: absolute;
						top:0;left:0;
						margin: auto;
						opacity: 1;
						z-index: 1;
						transition: opacity .3s ease;
					}
					&:after{
						content:'';
						width: 100%;
						height: 100%;
						background-image: linear-gradient(180deg, rgba(255,255,255,0.00) 38%, rgba(255,255,255,0.75) 53%, #FFFFFF 100%);
						position: absolute;
						top:0;left:0;
						margin: auto;
						z-index: 1;
						opacity: 0;
						transition: opacity .3s ease;
					}
					.img{
						width: 100%;
						height: 616px;
						background-position: center;
						background-repeat: no-repeat;
						background-size: cover;
						&.main{
							opacity: 1;
							transition: .3s ease;
							position:absolute;
							top:0;left:0;
							z-index: 0;
						}
					}
					
				}
				.item--content{
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 616px;
					z-index: 3;
					transform: skewX(4deg);

					display: flex;
					align-items: center;
					justify-content: center;
					flex-direction: column;
					.logo{
						position: absolute;
						top: 0;
						bottom: 0;
						left: 0;
						right: 10px;
						margin: auto;
					}
					.item--button{
						margin-top: 330px;
						margin-right: 20px;
					}
				}
				.item--char{
					width: 500px;
					height: 500px;
					position: absolute;
					top: -48px;
					left: -88px;
					transition: .3s ease;
					z-index: 0;
					opacity: 0;
					transform: skewX(4deg) scale(1.02);
					transform-origin: center bottom;
					pointer-events: none;
					.char{
						width: 500px;
						height: 500px;
						background-size: 500px;
						background-repeat: no-repeat;
						background-position: center;
					}
				}
			}
		}

		.division__list--m{
			margin: 0;
			.division__list--item--m{
				position: relative;
				height: 343px;
				.img{
					width: 100%;
					height: 100%;
					background-position: center;
					background-repeat: no-repeat;
					background-size: cover;
					position: absolute;
					top: 0;
					z-index: 0;
					left: 0;
					&:before{
						content: '';
						width: 100%;
						height: 100%;
						background-image: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.75) 100%);
						position: absolute;
						top: 0;
						left: 0;
						z-index: 0;
					}
					&:after{
						content: '';
						width: 100%;
						height: 100%;
						background-image: linear-gradient(180deg, rgba(252,219,58,0.09) 0%, rgba(0,0,0,0.00) 50%);
						position: absolute;
						top: 0;
						left: 0;
						z-index: 0;
					}
				}
				.item--content{
					display: flex;
					align-items: center;
					justify-content: center;
					flex-direction: column;
					height: 100%;
					.logo{
						position: relative;
						margin-bottom: 10px;
					}
				}
			}
		}
		.division-button{
			transition: .3s ease;
			.button-text{
				font-family: "Barlow", sans-serif;
				font-weight: 600;
				font-size: 18px;
				font-style: italic;
				fill: #F7B81C;
				transition: .3s ease;
			}
			.button-hover-state{
				opacity: 0;
				transition: .3s ease .07s;
			}
			.button-default-state{
				stroke: #F7B81C;
				fill: transparent;
				opacity: 1;
				transition: .3s ease .07s;
			}
			.button-glowing-wrapper{
				opacity: 0;
				transform: scale(0);
				transform-origin: center;
				transition: .3s ease;
			}
		}
		.item--category{
			width: 51px;
			height: 38px;
			position: absolute;
			top: 13px;
			right: 15px;
			i{
				width: 51px;
				height: 38px;
				display: block;
				&:before,
				&:after{
					content: '';
					width: 100%;
					height: 100%;
					display: block;
					background-repeat: no-repeat;
					background-position: center;
					transition: .3s ease;
					position: absolute;
					top:0;left:0;
				}
				&:before{opacity: 1;}
				&:after{opacity: 0;}
			}
			.ico-pc{
				&:before{
					background-image: url(/images/ico/ico-cat-pc.svg);
				}
				&:after{
					background-image: url(/images/ico/ico-cat-pc-bw.svg);
				}
			}
			.ico-mobile{
				&:before{
					background-image: url(/images/ico/ico-cat-mobile.svg);
				}
				&:after{
					background-image: url(/images/ico/ico-cat-mobile-bw.svg);
				}
			}
			.ico-console{
				&:before{
					background-image: url(/images/ico/ico-cat-console.svg);
				}
				&:after{
					background-image: url(/images/ico/ico-cat-console-bw.svg);
				}
			}
		}
	}
	.shop{
		.content{
			@include contentPadding;
			padding-top: 30px;
		}
		.card{
			border: 0;
		}
		.img-mobile{
			display:none;
			border-radius: 2px;
			overflow: hidden;
		}
		.img-desktop{
			display: block;
			border-radius: 5px;
			overflow: hidden;
		}
	}
	.achievement{
		.content{
			@include contentPadding;
		}
	}
	.share{
		.content{
			@include contentPadding;
		}

		.share--container{
			background-color: #fff;

			display: -webkit-box;
			display: flex;
		}
		.share--box-first{
			padding: 30px 24px;
			background-color: #fff;
			border-right: 1px solid #F0F0F0;
			margin-right: 5px;
			width: 244px;

			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			img{
				margin-bottom: 10px;
			}
			.title{
				color: #B6232A;
			}
		}
		.share--box-group{
			padding-top: 5px;
			padding-bottom: 5px;
			width: calc(100% - 249px);
		}
		.share--box{
			padding-right: 5px;
			width: susy-span(2 of 12);
			float: left;
			min-height: 1px;
			padding-bottom: 5px;
			&:nth-child(1),
			&:nth-child(2){
				width: susy-span(4 of 12);
				padding-bottom: 0;
			}
			&:nth-child(5),
			&:nth-child(6){
				padding-bottom: 0;
			}
			&.mini{
				width: susy-span(2 of 12);
				padding-bottom: 5px;
				&:nth-child(3),
				&:nth-child(4){
					padding-bottom: 0;
				}
			}
			&.padding-b0{padding-bottom: 0;}
			.img{
				background-size: cover;
				background-repeat: no-repeat;
				background-position: center;
				width: 100%;
				height: 100%;
			}
			a{
				height: 100%;
				width: 100%;
				position: relative;
				padding-bottom: 100%;
			}
			img{
				width: 100%;
				height: 100%;
				position: absolute;
				object-fit: cover;
			}
		}
		.share--box-last{
			padding:0;
			.m6{
				padding-right: gutter(of 12);
				padding-bottom: gutter(of 12);
				&:nth-child(3),
				&:nth-child(4){
					padding-bottom: 0;
				}
			}
			img{width: 100%;}
		}
	}
	.sponsor{
		.content{
			@include contentPadding;
			padding-bottom: 30px;
		}
		h5{margin-bottom: 20px;}
		.sponsor__container{
			width: susy-span(12 narrow of 12 narrow);
			min-height: 153px;
			background-color: #fff;
			padding: 12px;
			li{
				margin-right: 70px;
				&:last-child{
					margin-right: 0;
				}
			}
		}
	}
}

@media screen and (max-width: 1366px){
	.page--home{
		.division{
			max-height: 662px;
			.division__list{
				.division__list--item{
					height: 520px;
					.item--inner{height: 520px;}
					.item--content{height: 520px;}
				}
			}
		}
	}
}

@media screen and (max-width: 1280px){
	.page--home{
		.division{
			.division__text{
				height: 60px;
				.text-1{
					top: 16px;
					left: 49px;
					width: 190px;
				}
				.text-2{
					top: 10px;
					left: 217px;
					width: 600px;
				}
			}
		}
	}
}

@media screen and (max-width: 768px){
	.page--home{
		.main--banner{
			.banner--box{
				.img{
					transform: scale(1);
				}
				a{
					&:hover{
						.img{
							transform: scale(1);
						}
					}
				}
				.tag{
					right: 10px;
				}
			}

			#banner-news1,
			#banner-news2,
			#banner-news3,
			#banner-news4,
			#banner-news5{
				height: 30vh;
			}
		}
		.division{
			height: auto;
			max-height: 100%;
			background-image: linear-gradient(92deg, #FCDD3C 0%, #FF2E2E 100%);
			.division__text{height: 68px;}
			.containerfull{
				width: 100%;
				.division--d{display: none;}
				.division--m{display: block;}
			}
		}
		.shop{
			.content{padding-top: 10px;}
		}
		.share{
			.content{
				padding-top: 0;
			}
			.share--container{
				display: block;
			}
			.share--box-first{
				padding: 21px;
				border: 0;
				width: 100%
			}
			.share--box-group{
				width: 100%;
				.share--box{
					width: susy-span(6 of 12) !important;
					padding-bottom: 5px !important;
					padding-right: 2px !important;
					padding-left: 3px !important;
					&:nth-child(5),
					&:nth-child(6){
						display: none;
					}
				}
				#instagram-mobile{
					padding: 0 3px 0 2px;

				}
			}
		}
		.achievement{
			.btn--primary{margin: 0;}
		}
		.sponsor{
			.content{
				padding-top: 0;
				padding-bottom: 50px;
				h5{margin: 0 0 40px 0;}
				.flex{
					display: block;
					text-align: center;
					li{
						margin: 0 auto 40px;
						display: block;
					}
					a{
						display: block;
						img{float: none;}
					}
					img{margin: auto;}
				}
			}
		}
	}
}

@media screen and (max-width: 767px){
	.page--home{
		.division{
			.division__text{
				.text-1{display: none;}
				.text-2{display: none;}
				.text-1-m{display: block;}
				.text-2-m{display: block;}
			}
			.item--category{
				width: 30px;
				height: 24px;
				i{
					width: 30px;
					height: 24px;
				}
				.ico-pc{
					&:before{
						background-image: url(/images/ico/ico-cat-pc-mob.svg);
					}
				}
				.ico-mobile{
					&:before{
						background-image: url(/images/ico/ico-cat-mobile-mob.svg);
					}
				}
				.ico-console{
					&:before{
						background-image: url(/images/ico/ico-cat-console-mob.svg);
					}
				}
			}
		}
	}
}

@media screen and (max-width: 480px){
	.page--home{
		.main--banner{
			.banner--box{
				article{
					bottom: 10px;left:10px;
				}
				h5{font-size: 11px;margin-bottom: 3px;}
				h3{font-size: 18px;}
			}
		}
		.division{
			.containerfull{
				.teams--m{
					li{
						.teams--logo {
							img{
								max-width: 110px;
							}
						}
						.teams--link{
							font-size: 12px;
							padding: 7px 12px 8px;
						}
					}
				}
			}
			.division__list--m{
				.division__list--item--m{
					height: 200px;
					.item--content{
						.logo{
							max-width: 150px;
						}
						.item--button{
							svg{width: 107px; }	
						}
					}
				}
			}
		}
		.shop{
			.img-mobile{display:block;}
			.img-desktop{display: none;}
		}
		.share{
			.share--box-first{
				.title{font-size: 18px;}
			}
		}
	}
}

@media screen and (min-width: 1600px){
	.page--home{
		.main--banner{
			#banner-news1{
				height: 500px;
			}
			#banner-news2{
				height: 282px;
			}
			#banner-news3{
				height: 282px;
			}
			#banner-news4{
				height: 391px;
			}
			#banner-news5{
				height: 391px;
			}
		}
	}
}

// ANIMATION IN DESKTOP ONLY
@media screen and (min-width: 1025px){
	.page--home{
		.division{
			.division__list{
				.division__list--item{
					&:hover{
						z-index: 1;
						.item--inner{
							transform: scale(1.02);
							box-shadow: 0 4px 23px 0 rgba(0,0,0,0.50);
							border: 0;
						}
						.item__wrapper{
							&:before{opacity: 0;}
							&:after{opacity: 1;}
							.img{
								&.main{opacity: 0;}
							}
						}
						.division-button{
							.button-text{fill: #383838;}
							.button-hover-state{opacity: 1;transition: .3s ease !important;}
							.button-default-state{opacity: 0;transition: .3s ease !important;}
							.button-glowing-wrapper{
								opacity: 1;
								transform: scale(1);
								transition: .3s ease .02s !important;
							}
						}
						.item--content{
							.item--button{
								&:hover{
									.arrow-effect{
										&:before{animation: arrowIn .9s ease .2s infinite;}
										&:after{animation: arrowIn .9s ease .4s infinite;}
									}
								}
							}
						}
						.item--char{
							z-index: 2;
							opacity: 1;
							transform: skewX(4deg) scale(1);
							.char{
								animation: floating 5s ease-in-out .3s infinite;
							}
						}
						.item--category{
							i{
								&:before{opacity: 0;}
								&:after{opacity: 1;}
							}
						}
					}
					.item--content{
						.item--button{
							.arrow-effect{
								&:before{
									content:'';
									width: 37px;
									height: 36px;
									background-image: url(/images/division/btn-arrow-effect.png);
									background-repeat: no-repeat;
									background-position: center;
									position: absolute;
									opacity: 0;
									transition: .3s ease;
								}
								&:after{
									content:'';
									width: 37px;
									height: 36px;
									background-image: url(/images/division/btn-arrow-effect2.png);
									background-repeat: no-repeat;
									background-position: center;
									position: absolute;
									opacity: 0;
									transition: .3s ease;
								}
								&.top{
									&:before{
										right: -23px;
										top: -21px;
									}
									&:after{
										right: -42px;
										top: -38px;
									}
								}
								&.bottom{
									transform: rotate(180deg);
									&:before{
										right: -21px;
										top: -20px;
									}
									&:after{
										right: -40px;
										top: -38px;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

// ANDROID & IPHONE LANDSCAPE ONLY
@media only screen 
and (min-device-width: 320px) 
and (max-device-width: 736px)
and (orientation: landscape) { 
	.iphone,
	.android,
	.windowsphone{
		.page--home{
			.main--banner{
				#banner-news1,
				#banner-news2,
				#banner-news3,
				#banner-news4,
				#banner-news5{
					height: 300px;
				}
			}
			.division{
				.containerfull{
					.teams--m{
						li{
							height: 300px;
						}
					}
				}
			}
		}
	}
}

@media screen and (min-width: 768px)
and (max-width: 1024px){
	.page--home{
		.division{
			.division__text{
				.text-1{display: none;}
				.text-1-m{display: block;}
				.text-2{
					top: 5px;
					left: 112px;
					width: 640px;
				}
			}
		}
	}
}

// IPAD LANDSCAPE ONLY
@media only screen 
and (min-device-width : 768px) 
and (max-device-width : 1024px) 
and (orientation : landscape){
	.ipad{
		.page--home{
			.main--banner{
				.banner--box{
					.img{
						transform: scale(1);
					}
					a{
						&:hover{
							.img{
								transform: scale(1);
							}
						}
					}
				}
			}
			.division{
				.division__list{
					.division__list--item{
						height: 616px;
						.item--inner{height: 616px;}
						.item--content{height: 616px;}
					}
				}
				.division__text{
					height: 80px;
					.text-1{
						display: block !important;
						top: 25px;
						left: 49px;
						width: auto;
					}
					.text-1-m{display: none !important;}
					.text-2{
						top: 19px !important;
						left: 217px !important;
					}
				}
			}
		}
	}
}
// IPHONE X & WINDOWS PHONE LANDSCAPE ONLY
@media only screen 
and (min-device-width: 375px) 
and (max-device-width: 812px)
and (orientation: landscape) { 
	.iphone,
	.windowsphone{
		.page--home{
			.main--banner{
				.banner--box{
					.img{
						transform: scale(1);
					}
					a{
						&:hover{
							.img{
								transform: scale(1);
							}
						}
					}
				}
			}
			.division{
				.containerfull{
					.teams{
						li{
							.teams--logo{
								img{width: 80%;}
							}
							.teams--link{
								transform: translateY(0px);
								opacity:1;
								visibility: visible;

								transition: .3s ease;
							}
							&:hover{
								width: calc(20% + 5px);

								.teams--link{
									transform: translateY(0px);
									opacity:1;
									visibility: visible;
								}

								&:after{
									background-color: rgba(0,0,0,0.65);
									background-image: linear-gradient(-87deg, rgba(247,185,29,0.25) 0%, rgba(0,0,0,0.00) 50%);
								}
							}
						}
					}
				}
			}
		}
	}
}