.page--media-centre{
	.media-centre-container{
		padding: 30px 0 60px;
	}	
	.gallery--list{
		margin-left: -9px;
		margin-right: -9px;
		display: flex;
		flex-wrap: wrap;
		.gallery--list-item{
			width: 33.3%;
			padding: 9px;
			.gallery--list-item__a{
				position: relative;
				height: 100%;
				width: 100%;
				display: block;
				padding-bottom: 62%;
				cursor: pointer;
				&.official{
					&:before{
						content: '';
						width: 14px;
						height: 14px;
						background-image: url(/images/ico/ico-gallery-official.svg);
						background-repeat: no-repeat;
						background-position: center;
						position: absolute;
						left:10px;top:10px;

						transition: .3s ease;
						transform: translateY(0);
						opacity: 1;
						z-index: 1;
					}
					.gallery--list-detail{
						.detail--name{
							padding-left: 20px;
							&:before{
								content: '';
								width: 14px;
								height: 14px;
								background-image: url(/images/ico/ico-gallery-official.svg);
								background-repeat: no-repeat;
								background-position: center;
								position: absolute;
								left:0;top:3px;
							}
						}
					}
				}
				&:hover{
					&.official{
						&:before{
							transform: translateY(-10px);
							opacity: 0;
						}
					}
					.gallery--list-detail{
						opacity: 1;
					}
				}

				img{
					position: absolute;
					width: 100%;
					height: 100%;
					object-fit: cover;
				}
			}
			.gallery--list-detail{
				position: absolute;
				bottom: 0;
				left:0;
				width: 100%;
				cursor: default;

				transition: .3s ease;
				opacity: 0;
				&:before{
					content: '';
					background-image: linear-gradient(180deg, rgba(0,0,0,0.00) 0%, rgba(0,0,0,0.90) 100%);
					position: absolute;
					width: 100%;
					height:0;
					padding-top: 20%;
					bottom:0;left:0;
					z-index: 0;
				}
				.gallery--list-detail__inner{
					display: flex;
					align-items: center;
					justify-content: space-between;
					padding: 12px 20px;
					width: 100%;
				}
				.detail--name{
					@extend .opensans;
					color: #fff;
					letter-spacing: 0;
					font-size: 14px;
					position: relative;
					padding-right: 10px;
				}
				.detail-btn-download{
					position: relative;
					border: 1px solid #ff8c00;
					color: #ff8c00;
					@extend .barlow;
					font-size: 16px;
					letter-spacing: 0;
					padding: 4px 13px;
					background-color: transparent;
					transition: .3s ease;
					outline: 0;
					&:hover{
						background-color: #ff8c00;
						color: #fff;
					}
				}
			}
		}
	}
	.guidelines-container{
		margin: 58px 0 60px;
		&.download-asset-container{
			background-color: #f0f0f0;
			padding: 35px 0 30px;
		}
		.row{
			margin-bottom: 40px;
			&:last-child{margin: 0;}
			.col.m4{padding-right: 5%;}
		}
		.title{
			@extend .rubick;
			font-weight: 500;
			font-size: 24px;
			color: #363636;
			letter-spacing: 0.5px;
			margin-bottom: 30px;
		}
		.subtitle{
			@extend .barlow.medium;
			font-size: 24px;
			color: #363636;
			letter-spacing: 0.6px;
			margin-bottom: 7px;
		}
		p{
			@extend .opensans;
			font-size: 12px;
			color: #757575;
			letter-spacing: 0.3px;
			line-height: 21px;
			margin: 5px 0;
		}
		.palette-clr{
			@extend .opensans;
			font-size: 12px;
			color: #757575;
			letter-spacing: 0.3px;
			line-height: 21px;
			
			.clr-detail{
				display: flex;
				margin-top: 7px;
			}
			.clr-box{
				width: 82px;
				height: 82px;
				border-radius: 2px;
				&.with-border{
					border: 1px solid #979797;
				}
			}
			.clr-text{
				margin-left: 20px;
				.clr-row{
					display: block;
					.clr-label{width: 50px;display: inline-block;}
					b{
						@extend .opensans.semibold;
					}
				}
			}	
		}
		.logo-container{
			display: inline-block;
			vertical-align: top;
			padding: 0 30px;
			text-align: center;
			img{display: block;}
			.flex{
				img{
					margin-right: 20px;
					&:last-child{
						margin-right: 0;
					}
				}
			}
			small{margin-top: 8px;display: inline-block;}
			.true{
				position: relative;
				padding-left: 22px;
				&:before{
					content: '';
					width: 16px;
					height: 16px;
					background-image: url(/images/ico/ico-true.svg);
					background-size: 16px;
					background-repeat: no-repeat;
					position: absolute;
					left: 0;
				}
			}
			.false{
				@extend .true;
				&:before{
					background-image: url(/images/ico/ico-false.svg);
				}
			}
			.row{
				margin: 0;
				.logo-container-inner{
					max-width: 180px;
					margin-right: 20px;
					&:last-child{
						margin-right: 0;
					}
				}
			}
		}
		.btn--primary2{
			display: block;
			margin-top: 20px;
			max-width: 290px;
		}
	}
}

.popup__wrapper{
	.popup__content{
		&.gallery--popup{
			width: 1045px;
			max-width: 100%;
		}
	}
}
.gallery--popup{
	display: flex;
	position: relative;
	.gallery-img__container{
		width: 695px;
		background-color: #f8f8f8;
		.container__inner{
			width: 100%;
			height: 100%;
			display: flex;
			justify-content: center;
		}
	}
	.gallery-img__detail{
		width: calc(100% - 695px);
		background-color: #fff;
		padding: 20px 20px 58px;
		.button-download__container{
			margin-top: 20px;
			.btn--primary2{
				margin-bottom: 10px;
				display: block;
				&:last-child{
					margin-bottom: 0;
				}
			}
		}
		.detail--copy{
			margin-top: 40px;
			.title{
				@extend .barlow;
				font-weight: 600;
				font-size: 36px;
			}
			.category{
				@extend .opensans;
				letter-spacing: 0;
				font-size: 14px;
				position: relative;
				margin: 5px 0 25px;
				&.official{
					padding-left: 20px;
					&:before{
						content: '';
						width: 14px;
						height: 14px;
						background-image: url(/images/ico/ico-gallery-official.svg);
						background-repeat: no-repeat;
						background-position: center;
						position: absolute;
						left:0;top:3px;
					}
				}
			}
			.desc{
				@extend .opensans;
				font-size: 14px;
				color: #757575;
				letter-spacing: 0;
				line-height: 19px;
				.label{
					margin-bottom: 6px;
					&:last-child{margin: 0;}
					span{
						width: 85px;
						display: inline-block;
					}
				}
			}
		}
	}

	.gallery-img--tab{
		display: flex;
		margin: 0;
		.tab-item{
			display: flex;
			align-items: center;
			margin-right: 10px;
			cursor: pointer;
			&:last-child{
				margin-right: 0;
			}
			&:hover{
				.tab-item--text{color: darken(#969696, 5%);}
			}
			&.active{
				.tab-item--thumb{
					.thumb__inner{
						border: 2px solid #ff8c00;
					}
				}
				.tab-item--text{color: #ff8c00;}
			}
			.tab-item--thumb{
				.thumb__inner{
					width: 44px;
					height: 44px;
					background-color: #e5e5e5;
					border-radius: 2px;
					padding: 4px;
					border: 2px solid transparent;

					display: flex;
					align-items: center;
					justify-content: center;

					transition: .3s ease;
				}
				.img-landscape{
					width: 100%;
				}
				.img-portrait{
					height: 100%;
				}
			}
			.tab-item--text{
				padding: 8px;
				@extend .barlow;
				font-weight: 500;
				font-size: 12px;
				color: #969696;
				letter-spacing: 0.5px;
				text-align: center;
				transition: .3s ease;
			}
		}
	}

	.btn--close{
		position: absolute;
		top: -38px;
		right: 0;
		background-color: #fff;
		border-radius: 2px;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 28px;
		height: 28px;
		text-align: center;
		padding-left: 2px;
		.material-icons{font-size: 28px;}
	}
}

@media screen and (max-width: 768px){
	.page--media-centre{
		.media-centre-container{
			padding-bottom: 0;
		}
		.guidelines-container{
			margin: 30px 0 40px;
			&.download-asset-container{
				padding: 8px 0 12px;
				.row{
					.col.m4{
						padding: 0;
					}
				}
			}
			.logo-container{
				padding: 0 30px 0 0;
				text-align: left;
				.row{
					.logo-container-inner{
						margin: 10px;
						img{margin: 0;}
					}
				}
				.label-twoimage{
					max-width: 180px;
					display: block;
					margin: 8px 10px 0;
				}
				&.xs-margin0{
					img{margin: 0 !important;}
				}
			}
			.img-text-mbl{
				margin-top: 30px;
			}
			.content{
				padding: 10px 15px;
			}
			.title{margin-bottom: 20px;}
			.xs-margin0{margin: 0 !important;}
			p{margin-bottom: 10px;}
		}
		.palette-clr{
			padding-bottom: 15px;
		}

		.gallery--list{
			.gallery--list-item{
				width: 50%;
				.gallery--list-detail{
					opacity: 1;
					.detail-btn-download{
						font-size: 0;
						width: 25px;
						height: 25px;
						background-image: url(/images/ico/ico-dowload.svg);
						background-repeat: no-repeat;
						background-position: center;
						&:hover{
							background-color: transparent;
						}
					}
				}
				.gallery--list-item__a{
					&.official{
						&:before{
							display: none;
						}
					}
				}
			}
		}
	}
	
	.popup__wrapper{
		.popup__content{
			&.gallery--popup{
				width: 100%;
			}
		}
	}
	.gallery--popup{
		display: block;
		.gallery-img__container{
			width: 100%;
			.container__inner{display: block;}
			img{
				margin: auto;
			}
		}
		.gallery-img__detail{
			width: 100%;
		}
		.btn--close{
			right: 15px;
			top: 15px;
		}
		.gallery-img--tab{
			.tab-item{width: 50%;}
		}
	}
}

@media screen and (max-width: 520px){
	.page--media-centre{
		.guidelines-container{
			.logo-container{
				padding: 0;
				margin-left: -10px;
				margin-right: -10px;
				.flex{
					img{
						margin-right: 10px;
					}
				}
				img{
					margin: 10px;
				}
			}
			.btn--primary2{
				max-width: 100%;
			}
		}

		.gallery--list{
			.gallery--list-item{
				width: 100%;
			}
		}
	}

	.popup__wrapper{
		.popup__content{
			&.gallery--popup{
				overflow: auto;
			}
		}
	}

	.gallery--popup{
		.btn--close{
			right: 10px;
			top: 10px;
		}
	}
}